export const topTenWasteByDrug =
{
    count: 10,
    items: [
        {
            id: 673,
            drug:'fentaNYL INJ',
            amountOfWaste: 85,
            date: '2024-01-20'
        },
        {
            id: 674,
            drug:'MIDAZOLAM INJ',
            amountOfWaste: 71,
            date: '2024-01-20'
        },
        {
            id: 675,
            drug:'HYDROmorphone INJ',
            amountOfWaste: 68,
            date: '2024-01-20'
        },
        {
            id: 676,
            drug:'MORPHINE INJ',
            amountOfWaste: 60,
            date: '2024-01-20'
        },
        {
            id: 677,
            drug:'oxyCODONE TAB',
            amountOfWaste: 53,
            date: '2024-01-20'
        },
        {
            id: 678,
            drug:'PROFONOL INJ',
            amountOfWaste: 48,
            date: '2024-01-20'
        },
        {
            id: 679,
            drug:'LORazepam INJ',
            amountOfWaste: 33,
            date: '2024-01-20'
        },
        {
            id: 680,
            drug:'HYDROmorphone PCA',
            amountOfWaste: 29,
            date: '2024-01-20'
        },
        {
            id: 681,
            drug:'fentaNYL PATCH',
            amountOfWaste:21,
            date: '2024-01-20'
        },
        {
            id: 682,
            drug:'MORPHINE ORAL SYRINGE',
            amountOfWaste: 14,
            date: '2024-01-20'
        },
    ]
}
