import { Injectable } from "@angular/core";
import { EChartType, IEChartFactory } from '@midas/shared/common';
import * as echarts from 'echarts';

@Injectable({ providedIn: 'root' })
export class EChartsService {

  constructor(){}

  eChartsFactory = (eChartData: IEChartFactory):echarts.EChartsOption | undefined =>{
    switch(eChartData.type){
      case EChartType.gauge: {
        return this.getDataForGaugeChart(eChartData);
      }
      case EChartType.bar: {
        return this.getDataForBarChart(eChartData);
      }
      case EChartType.pie: {
        return this.getDataForPieChart(eChartData);
      }
      case EChartType.pieDoughnut: {
        return this.getDataForPieDoughnutChart(eChartData);
      }
      default:
        return undefined;
    }

  }


  private getDataForGaugeChart = (eChartData: IEChartFactory): echarts.EChartsOption | undefined  => {
    return {
      series: [
        {
          type: 'gauge',
          center: ['50%', '60%'],
          startAngle: 200,
          endAngle: -20,
          min: 0,
          max: 100,
          splitNumber: 10,
          itemStyle: {
            color: '#FFAB91'
          },
          progress: {
            show: true,
            width: 30
          },

          pointer: {
            show: false
          },
          axisLine: {
            lineStyle: {
              width: 30
            }
          },
          axisTick: {
            distance: -45,
            splitNumber: 5,
            lineStyle: {
              width: 2,
              color: '#999'
            }
          },
          splitLine: {
            distance: -36,
            length: 14,
            lineStyle: {
              width: 3,
              color: '#999'
            }
          },
          axisLabel: {
            distance: -20,
            color: '#999',
            fontSize: 20
          },
          anchor: {
            show: false
          },
          title: {
            show: false
          },
          detail: {
            valueAnimation: true,
            width: '60%',
            lineHeight: 40,
            borderRadius: 8,
            offsetCenter: [0, '-15%'],
            fontSize: 60,
            fontWeight: 'bolder',
            formatter: '{value}',
            color: 'inherit'
          },
          data: [
            {
              value: eChartData.data
            }
          ]
        }
      ]
    } as echarts.EChartsOption;
  }

  private getDataForBarChart = (eChartData: IEChartFactory): echarts.EChartsOption | undefined  => {

    return {
      dataset: [
        {
          dimensions: eChartData.datasetKeys,
          source: eChartData.data
        },
        {
          transform: {
            type: 'sort',
            config: { dimension: eChartData.sortValue, order: eChartData.sort }
          }
        }
      ],
      xAxis: {
        type: 'category',
        axisLabel: { interval: 0, rotate: 30 }
      },
      yAxis: {},
      series: {
        type: 'bar',
        encode: eChartData.encode,
        datasetIndex: 1
      }
    } as echarts.EChartsOption;
  }

  private getDataForPieChart = (eChartData: IEChartFactory): echarts.EChartsOption | undefined  => {

    return {
      dataset: [
        {
          dimensions: ['hospital', 'amountOfWaste', 'date'],
          source: eChartData.data
        }
      ],
      series: {
        type: 'pie',
        radius: '50%',
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    } as echarts.EChartsOption;
  }

  private getDataForPieDoughnutChart = (eChartData: IEChartFactory): echarts.EChartsOption | undefined  => {

    return {
      tooltip: {
        trigger: 'item'
      },
      series: [
        {
          name: 'Access From',
          type: 'pie',
          radius: ['30%', '70%'],
          avoidLabelOverlap: false,
          emphasis: {
            label: {
              show: true,
              fontSize: 10,
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: eChartData.data
        }
      ]
    } as echarts.EChartsOption;
  }
}
