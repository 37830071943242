/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { MidasEffectError } from '@midas/shared/common';
import { createAction, props } from '@ngrx/store';

export const login = createAction(
  '[Auth] Login',
  props<{
    username: string;
    password: string;
  }>()
);
export const loginRequiresPasswordUpdate = createAction(
  '[Auth] Login Requires Password Update',
  props<{
    username: string;
    currentTemporaryPassword: string;
  }>()
);
export const loginRequiresMfaCode = createAction(
  '[Auth] Login Requires MFA Code',
  props<{
    username: string;
    session: string;
  }>()
);
export const loginSuccess = createAction('[Auth] Login Success', props<{ token: string }>());
export const loginError = createAction('[Auth] Login Error', props<MidasEffectError>());

export const logoutLocal = createAction('[Auth] Logout Local');
export const loginGroupAccessError = createAction('[Auth] Login Group Access Error');

export const setNewPassword = createAction(
  '[Auth] Set New Password',
  props<{
    username: string;
    currentPassword: string;
    newPassword: string;
  }>()
);
export const setNewPasswordSuccess = createAction('[Auth] Set New Password Success');
export const setNewPasswordError = createAction('[Auth] Set New Password Error', props<MidasEffectError>());

export const setNewPasswordRequiresMfa = createAction(
  '[Auth] Set New Password Requires MFA',
  props<{
    username: string;
    session: string;
    newPassword: string;
  }>()
);

export const verifySetNewPassword = createAction(
  '[Auth] Verify Set New Password',
  props<{
    username: string;
    code: string;
    session: string;
    newPassword: string;
  }>()
);
export const verifySetNewPasswordSuccess = createAction('[Auth] Verify Set New Password Success');
export const verifySetNewPasswordError = createAction(
  '[Auth] Verify Set New Password Error',
  props<MidasEffectError>()
);

export const forgotPassword = createAction('[Auth] Forgot Password', props<{ username: string }>());
export const forgotPasswordSuccess = createAction(
  '[Auth] Forgot Password Success',
  props<{ deliveryMedium: string; destination: string; username: string }>()
);
export const forgotPasswordError = createAction('[Auth] Forgot Password Error', props<MidasEffectError>());

export const confirmForgotPassword = createAction(
  '[Auth] Confirm Forgot Password',
  props<{ confirmCode: string; username: string; password: string }>()
);
export const confirmForgotPasswordSuccess = createAction(
  '[Auth] Confirm Forgot Password Success',
  props<{ token: string }>()
);
export const confirmForgotPasswordError = createAction(
  '[Auth] Confirm Forgot Password Error',
  props<MidasEffectError>()
);

export const verifyMfaCode = createAction(
  '[Auth] Verify Mfa Code',
  props<{ code: string; username: string; session: string }>()
);
export const verifyMfaCodeSuccess = createAction(
  '[Auth] Verify Mfa Code Success',
  props<{ token: string }>()
);
export const verifyMfaCodeError = createAction('[Auth] Verify Mfa Code Error', props<MidasEffectError>());
