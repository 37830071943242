/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { SortCriteriaItem } from './base.types';
import { Dispense } from './dispense.model';
import { FormularyWitnessRequirement } from './formulary.model';
import { ManagedUser } from './managed-user.model';
import { IWorkflow } from './workflow.model';

export enum WastingReason {
  ExpectedWaste = 1,
  Refused = 2,
  OrderChanged = 4,
  Dropped = 8,
  Others = 16,
  Override = 32,
}

export enum WorkFlowStatus {
  Unknown = 0,
  CancelledLogOut = 1,
  CancelledAutoLogOut = 2,
  CancelledHome = 4,
  Completed = 8,
  Pending = 16,
  Unresolved = 32,
  Resolved = 64,
  CancelledDisconnected = 128,
  CancelledCameraFailed = 256,
  CompletedDisconnection = 512,
  CompletedCameraFailed = 1024,
}

export enum MedicationForm {
  Tablet = 1,
  Suppository = 2,
  Film = 4,
  Patch = 8,
  Capsule = 16,
  Ampule = 32,
  Vial = 64,
  Syringe = 128,
  Liquid = 256,
  Inhaler = 512,
  Others = 1024,
}

export enum ExceptionWastingReason {
  FoundOnFloor = 1,
  Others = 2,
}

export interface Wasting extends IWorkflow {
  amountWasted: number;
  units: string | null;
  amountWastedInSupplySize: number;
  supplySizeUnit: string;
  calculatedWaste: number;
  expectedWasteInSupplySize: number;
  wastedBySupplySizeOnly: boolean;
  reasonForWaste: WastingReason | null;
  otherComments: string | null;
  otherReasons: string | null;
  wastingVideoFilePath: string;
  wastingVideoDuration: string;
  witnessVideoFilePath: string;
  screenVideoFilePath: string;
  wastingTime: string;
  locationId: number;
  locationName: string;
  cartId: number;
  cartName: string;

  wasteUser: ManagedUser;
  witnessUser: ManagedUser | null;
  witnessUserId: number | null;

  dispense: Dispense | null;
  dispenseId: number | null;
  isLastWaste: boolean;
  toBeCollected: boolean;
  wastingStatus: WorkFlowStatus; // replace with WorkflowStatus in IWorkflow eventually
  witnessRequirement: FormularyWitnessRequirement | null;
  witnessType: FormularyWitnessRequirement | null;

  // below fields are only used for cancelled or auto-logged out wasting where patient or medication has been selected
  // for non-exception wastings
  selectedPatientID: number | null;
  selectedFormularyID: number | null;

  // below fields are only used for Exception Wastes
  medicationFoundLocation: string | null;
  color: string | null;
  formOfMedication: MedicationForm;
  otherMedicationForm: string | null;
  genericName: string | null;
  tradeName: string | null;
  supplyStrength: number | null;
  supplyStrengthUnit: string | null;
  isExceptionWaste: boolean;
  exceptionFormularyID: number | null;
  exceptionWasteReason: ExceptionWastingReason | null;
}

export const canWitnessWastingStatuses = [
  WorkFlowStatus.Pending,
  WorkFlowStatus.Completed,
  WorkFlowStatus.CancelledAutoLogOut,
  WorkFlowStatus.CancelledDisconnected,
  WorkFlowStatus.CancelledHome,
  WorkFlowStatus.CancelledLogOut,
  WorkFlowStatus.Unknown,
];

export enum WastingSortCriteria {
  Date,
  Location,
  Cart,
  Waster,
  Witness,
  Patient,
  Formulary,
}

export type WastingSortCriteriaItem = SortCriteriaItem<typeof WastingSortCriteria>;

export interface QueryWastingsRequest {
  limit: number;
  offset: number;
  searchString?: string;
  locations?: number[];
  carts?: number[];
  patients?: number[];
  wasters?: number[];
  witnesses?: number[];
  formulary?: number[];
  endDate?: string | null;
  startDate?: string | null;
  sortCriteria?: WastingSortCriteriaItem[];
  statuses: WorkFlowStatus[];
  showExceptionWastes?: boolean;
  showUserWastes?: boolean;
}

export enum WastingPageType {
  AnytimeWitness,
  HistoricalWastes,
}
