export const topTenWasteByPerson = 
{ 
    count: 10,
    waste: [
        {
            id: 673,
            user:'Hannah Krause',
            amountOfWaste: Math.floor(Math.random() * 100),
            date: '2024-01-20'
        },
        {
            id: 674,
            user:'Zhao Qian',
            amountOfWaste: Math.floor(Math.random() * 100),
            date: '2024-01-20'
        },
        {
            id: 675,
            user:'Jasmin Krause ',
            amountOfWaste: Math.floor(Math.random() * 100),
            date: '2024-01-20'
        },
        {
            id: 676,
            user:'Li Lei',
            amountOfWaste: Math.floor(Math.random() * 100),
            date: '2024-01-20'
        },
        {
            id: 677,
            user:'Karle Neumann',
            amountOfWaste: Math.floor(Math.random() * 100),
            date: '2024-01-20'
        },
        {
            id: 678,
            user:'Adrian Groß',
            amountOfWaste: Math.floor(Math.random() * 100),
            date: '2024-01-20'
        },
        {
            id: 679,
            user:'Mia Neumann',
            amountOfWaste: Math.floor(Math.random() * 100),
            date: '2024-01-20'
        },
        {
            id: 680,
            user:'Böhm Fuchs',
            amountOfWaste: Math.floor(Math.random() * 100),
            date: '2024-01-20'
        },
        {
            id: 681,
            user:'Han Meimei',
            amountOfWaste: Math.floor(Math.random() * 100),
            date: '2024-01-20'
        },
        {
            id: 682,
            user:'Jhon Daoe',
            amountOfWaste: Math.floor(Math.random() * 100),
            date: '2024-01-20'
        },
    ]
}