export const overview_views = {
  carts: [
    {
      id: Math.floor(Math.random() * 1000),
      name: `First Factory Main Hospital`,
      location: [
        { id: 1, name: 'Manhattan - Building A', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 435,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull:8,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 12,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 1,
      buildingName: 'Manhattan - Building A',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `2N HQ BA - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Manhattan - Building A', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 15,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 21,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 1,
      buildingName: 'Manhattan - Building A',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `2S HQ BA - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Manhattan - Building A', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 23,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 54,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 1,
      buildingName: 'Manhattan - Building A',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `3S HQ BA - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Manhattan - Building A', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 45,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 60,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 1,
      buildingName: 'Manhattan - Building A',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `4N HQ BA - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Manhattan - Building A', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 9,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 22,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 1,
      buildingName: 'Manhattan - Building A',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `4S HQ BA - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Manhattan - Building A', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 30,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 75,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 1,
      buildingName: 'Manhattan - Building A',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `Central Pharmacy HQ BA - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Manhattan - Building A', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 12,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 23,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 1,
      buildingName: 'Manhattan - Building A',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `PACU HQ BA - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Manhattan - Building A', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 9,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 71,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 1,
      buildingName: 'Manhattan - Building A',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `ER HQ BA - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Manhattan - Building A', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 14,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 45,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 1,
      buildingName: 'Manhattan - Building A',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `ICU1 HQ BA - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Manhattan - Building A', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 81,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 11,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 2,
      buildingId: 1,
      buildingName: 'Manhattan - Building A',
    },
    



    {
      id: Math.floor(Math.random() * 1000),
      name: `2N BN BB - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Manhattan - Building B', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 100,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 100,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 3,
      buildingId: 2,
      buildingName: 'Manhattan - Building B',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `3N BN BB - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Manhattan - Building B', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 11,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 18,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 2,
      buildingName: 'Manhattan - Building B',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `3S BN BB - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Manhattan - Building B', description: '', parentId: 0, locationType: 'building' },
        { id: 1, name: '3S', description: '', parentId: 0, locationType: 'floor' },
        { id: 1, name: 'BN', description: '', parentId: 0, locationType: 'room' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 22,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 8,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 2,
      buildingName: 'Manhattan - Building B',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `4N BN BB - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Manhattan - Building B', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 9,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 22,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 2,
      buildingName: 'Manhattan - Building B',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `4S BN BB - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Manhattan - Building B', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 30,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 75,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 2,
      buildingName: 'Manhattan - Building B',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `PACU BN BB - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Manhattan - Building B', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 9,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 71,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 2,
      buildingName: 'Manhattan - Building B',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `ER BN BB - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Manhattan - Building B', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 3,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 20,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 2,
      buildingName: 'Manhattan - Building B',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `ICU1 BN BB - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Manhattan - Building B', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 17,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 11,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 2,
      buildingName: 'Manhattan - Building B',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `Central Pharmacy BN BB - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Manhattan - Building B', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 22,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 14,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 2,
      buildingName: 'Manhattan - Building B',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `2S BN BB - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Manhattan - Building B', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 33,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 21,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 2,
      buildingName: 'Manhattan - Building B',
    },





    {
      id: Math.floor(Math.random() * 1000),
      name: `2N BX BC - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Manhattan - Building C', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 34,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 11,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 3,
      buildingName: 'Manhattan - Building C',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `2S BX BC - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Manhattan - Building C', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 45,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 56,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 3,
      buildingName: 'Manhattan - Building C',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `3N BX BC - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Manhattan - Building C', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 8,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 22,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 3,
      buildingName: 'Manhattan - Building C',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `3S BX BC - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Manhattan - Building C', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 1,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 60,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 3,
      buildingName: 'Manhattan - Building C',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `4N BX BC - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Manhattan - Building C', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 9,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 22,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 3,
      buildingName: 'Manhattan - Building C',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `4S BX BC - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Manhattan - Building C', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 30,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 75,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 3,
      buildingName: 'Manhattan - Building C',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `ICU1 BX BC - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Manhattan - Building C', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 64,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 11,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 3,
      buildingName: 'Manhattan - Building C',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `Central Pharmacy BX BC - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Manhattan - Building C', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 45,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 14,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 3,
      buildingName: 'Manhattan - Building C',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `PACU BX BC - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Manhattan - Building C', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 9,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 71,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 3,
      buildingName: 'Manhattan - Building C',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `ER BX BC - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Manhattan - Building C', description: '', parentId: 0, locationType: 'building' },
        { id: 1, name: 'ER', description: '', parentId: 0, locationType: 'floor' },
        { id: 1, name: 'BX', description: '', parentId: 0, locationType: 'room' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 13,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 8,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 3,
      buildingName: 'Manhattan - Building C',
    },





    {
      id: Math.floor(Math.random() * 1000),
      name: `3N HQ BA - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Brooklyn - Building A', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 11,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 11,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 4,
      buildingName: 'Brooklyn - Building A',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `2N HQ BA - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Brooklyn - Building A', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 11,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 33,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 4,
      buildingName: 'Brooklyn - Building A',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `2S HQ BA - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Brooklyn - Building A', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 20,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 12,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 4,
      buildingName: 'Brooklyn - Building A',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `3S HQ BA - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Brooklyn - Building A', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 45,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 60,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 4,
      buildingName: 'Brooklyn - Building A',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `4N HQ BA - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Brooklyn - Building A', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 9,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 22,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 4,
      buildingName: 'Brooklyn - Building A',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `4S HQ BA - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Brooklyn - Building A', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 30,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 75,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 4,
      buildingName: 'Brooklyn - Building A',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `Central Pharmacy HQ BA - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Brooklyn - Building A', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 12,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 23,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 4,
      buildingName: 'Brooklyn - Building A',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `PACU HQ BA - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Brooklyn - Building A', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 9,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 71,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 4,
      buildingName: 'Brooklyn - Building A',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `ER HQ BA - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Brooklyn - Building A', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 14,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 45,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 4,
      buildingName: 'Brooklyn - Building A',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `ICU1 HQ BA - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Brooklyn - Building A', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 81,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 11,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 2,
      buildingId: 4,
      buildingName: 'Brooklyn - Building A',
    },
    







    {
      id: Math.floor(Math.random() * 1000),
      name: `2N BN BB - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Brooklyn - Building B', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 33,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 56,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 5,
      buildingName: 'Brooklyn - Building B',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `2S BN BB - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Brooklyn - Building B', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 36,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 7,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 5,
      buildingName: 'Brooklyn - Building B',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `3N BN BB - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Brooklyn - Building B', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 24,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 20,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 5,
      buildingName: 'Brooklyn - Building B',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `3S BN BB - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Brooklyn - Building B', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 66,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 45,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 5,
      buildingName: 'Brooklyn - Building B',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `4N BN BB - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Brooklyn - Building B', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 9,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 22,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 5,
      buildingName: 'Brooklyn - Building B',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `4S BN BB - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Brooklyn - Building B', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 30,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 75,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 5,
      buildingName: 'Brooklyn - Building B',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `PACU BN BB - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Brooklyn - Building B', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 9,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 71,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 5,
      buildingName: 'Brooklyn - Building B',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `ER BN BB - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Brooklyn - Building B', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 3,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 20,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 5,
      buildingName: 'Brooklyn - Building B',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `ICU1 BN BB - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Brooklyn - Building B', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 45,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 11,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 5,
      buildingName: 'Brooklyn - Building B',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `Central Pharmacy BN BB - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Brooklyn - Building B', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 89,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 21,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 2,
      buildingId: 5,
      buildingName: 'Brooklyn - Building B',
    },





    {
      id: Math.floor(Math.random() * 1000),
      name: `2N BX BC - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Brooklyn - Building C', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 55,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 23,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 6,
      buildingName: 'Brooklyn - Building C',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `2S BX BC - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Brooklyn - Building C', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 34,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 12,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 6,
      buildingName: 'Brooklyn - Building C',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `3N BX BC - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Brooklyn - Building C', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 67,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 12,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 6,
      buildingName: 'Brooklyn - Building C',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `3S BX BC - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Brooklyn - Building C', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 33,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 60,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 6,
      buildingName: 'Brooklyn - Building C',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `4N BX BC - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Brooklyn - Building C', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 9,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 22,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 6,
      buildingName: 'Brooklyn - Building C',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `4S BX BC - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Brooklyn - Building C', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 30,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 75,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 6,
      buildingName: 'Brooklyn - Building C',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `ICU1 BX BC - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Brooklyn - Building C', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 64,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 11,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 6,
      buildingName: 'Brooklyn - Building C',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `Central Pharmacy BX BC - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Brooklyn - Building C', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 45,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 14,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 6,
      buildingName: 'Brooklyn - Building C',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `PACU BX BC - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Brooklyn - Building C', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 9,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 71,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 6,
      buildingName: 'Brooklyn - Building C',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `ER BX BC - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Brooklyn - Building C', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 13,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 89,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 2,
      buildingId: 6,
      buildingName: 'Brooklyn - Building C',
    },





    {
      id: Math.floor(Math.random() * 1000),
      name: `3N HQ BA - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Bronx - Building A', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 100,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 100,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 3,
      buildingId: 7,
      buildingName: 'Bronx - Building A',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `2N HQ BA - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Bronx - Building A', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 11,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 33,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 7,
      buildingName: 'Bronx - Building A',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `2S HQ BA - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Bronx - Building A', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 20,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 12,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 7,
      buildingName: 'Bronx - Building A',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `3S HQ BA - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Bronx - Building A', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 45,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 60,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 7,
      buildingName: 'Bronx - Building A',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `ICU1 HQ BA - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Bronx - Building A', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 23,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 11,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 7,
      buildingName: 'Bronx - Building A',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `Central Pharmacy HQ BA - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Bronx - Building A', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 12,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 23,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 7,
      buildingName: 'Bronx - Building A',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `PACU HQ BA - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Bronx - Building A', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 9,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 71,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 7,
      buildingName: 'Bronx - Building A',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `ER HQ BA - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Bronx - Building A', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 14,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 45,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 7,
      buildingName: 'Bronx - Building A',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `4N HQ BA - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Bronx - Building A', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 9,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 22,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 7,
      buildingName: 'Bronx - Building A',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `4S HQ BA - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Bronx - Building A', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 30,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 75,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: true,
      statusId: 4,
      buildingId: 7,
      buildingName: 'Bronx - Building A',
    },
    





    {
      id: Math.floor(Math.random() * 1000),
      name: `2N BN BB - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Bronx - Building B', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 23,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 33,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 8,
      buildingName: 'Bronx - Building B',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `2S BN BB - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Bronx - Building B', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 44,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 12,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 8,
      buildingName: 'Bronx - Building B',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `3N BN BB - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Bronx - Building B', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 56,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 71,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 8,
      buildingName: 'Bronx - Building B',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `3S BN BB - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Bronx - Building B', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 22,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 8,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 8,
      buildingName: 'Bronx - Building B',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `4N BN BB - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Bronx - Building B', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 9,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 22,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 8,
      buildingName: 'Bronx - Building B',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `4S BN BB - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Bronx - Building B', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 30,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 75,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 8,
      buildingName: 'Bronx - Building B',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `PACU BN BB - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Bronx - Building B', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 9,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 71,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 8,
      buildingName: 'Bronx - Building B',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `ER BN BB - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Bronx - Building B', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 3,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 20,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 8,
      buildingName: 'Bronx - Building B',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `ICU1 BN BB - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Bronx - Building B', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 24,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 11,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 8,
      buildingName: 'Bronx - Building B',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `Central Pharmacy BN BB - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Bronx - Building B', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 89,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 21,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 2,
      buildingId: 8,
      buildingName: 'Bronx - Building B',
    },




    {
      id: Math.floor(Math.random() * 1000),
      name: `2N BX BC - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Bronx - Building C', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 100,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 33,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 3,
      buildingId: 9,
      buildingName: 'Bronx - Building C',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `2S BX BC - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Bronx - Building C', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 1,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 12,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 9,
      buildingName: 'Bronx - Building C',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `3N BX BC - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Bronx - Building C', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 5,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 45,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 9,
      buildingName: 'Bronx - Building C',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `3S BX BC - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Bronx - Building C', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 1,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 60,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 9,
      buildingName: 'Bronx - Building C',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `4N BX BC - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Bronx - Building C', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 9,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 22,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 9,
      buildingName: 'Bronx - Building C',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `4S BX BC - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Bronx - Building C', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 30,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 75,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 9,
      buildingName: 'Bronx - Building C',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `ICU1 BX BC - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Bronx - Building C', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 64,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 11,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 9,
      buildingName: 'Bronx - Building C',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `Central Pharmacy BX BC - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Bronx - Building C', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 45,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 14,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 9,
      buildingName: 'Bronx - Building C',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `PACU BX BC - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Bronx - Building C', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 9,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 71,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 1,
      buildingId: 9,
      buildingName: 'Bronx - Building C',
    },
    {
      id: Math.floor(Math.random() * 1000),
      name: `ER BX BC - # ${Math.floor(Math.random() * 999)}`,
      location: [
        { id: 1, name: 'Bronx - Building C', description: '', parentId: 0, locationType: 'building' },
      ],
      locationId: 989,
      locationName: `General Hospital HQ - Manhattan`,
      organizationName: 'General Hospital HQ - Manhattan',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 13,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: '1111',
          capacity: 200,
          wastedCount: 1,
          percentFull: 89,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      InUse: false,
      statusId: 2,
      buildingId: 9,
      buildingName: 'Bronx - Building C',
    },
  ],
};
