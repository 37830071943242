/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { Injectable } from '@angular/core';
import { EControlZonePermissions, LocationPermissions, UserPermissions } from '@midas/shared/common';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { UserSelectors } from './stores/user';

@Injectable({ providedIn: 'root' })
export class UserPermissionsService {
  constructor(private store: Store) {}

  checkUserPermissions(neededPermissions: UserPermissions): Observable<boolean> {
    return this.store.select(UserSelectors.getUserState).pipe(
      map(({ permissions }): boolean => {
        if ((permissions & neededPermissions) === neededPermissions) {
          return true;
        }
        return false;
      }),
    );
  }

  checkUserPermissionsForControlZone(neededPermissions: EControlZonePermissions): Observable<boolean> {
    return this.store.select(UserSelectors.getUserState).pipe(
      map(({ userGroups }): boolean => {
        const hasAccessToControlZone = userGroups.find((group) => {
          return (
            group.permissions === LocationPermissions.CanAccessToControlZone &&
            !!(group.controlZonePermissions & neededPermissions)
          );
        });
        return hasAccessToControlZone ? true : false;
      }),
    );
  }
}
