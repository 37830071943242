/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
export const LOCAL_STORAGE_AUTH_REFRESH_TOKEN_KEY = 'midas-refresh-token';

class ILocalStorageAuthRefreshTokenKey {
  get authRefreshToken() {
    return localStorage.getItem(LOCAL_STORAGE_AUTH_REFRESH_TOKEN_KEY) || '';
  }

  set authRefreshToken(token: string) {
    localStorage.setItem(LOCAL_STORAGE_AUTH_REFRESH_TOKEN_KEY, token);
  }

  deleteRefreshToken() {
    localStorage.removeItem(LOCAL_STORAGE_AUTH_REFRESH_TOKEN_KEY);
  }
}

export const LocalStorageAuthRefreshTokenKey = new ILocalStorageAuthRefreshTokenKey();
