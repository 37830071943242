export const anytimeWitness = {
  count: 9,
  items: [
    {
      id: 5414,
      locationId: 411,
      locationName: 'Midas HS General',
      cartId: 705,
      cartName: 'VIEW 1',
      videoThumbnailUrl:
        'https://midas-cloud-video-storage.s3.amazonaws.com/?AWSAccessKeyId=AKIAULWL6QUYII6RUUZP&Expires=1730120961&Signature=xb6jvm0A%2FQmQR1VChIA%2FgTv9zmc%3D',
      wasteUser: {
        id: 1241,
        username: 'nurse1',
        firstName: 'Michael',
        lastName: 'LaFauci',
      },
      witnessUser: null,
      amountWasted: 0.5,
      units: 'mg',
      amountWastedInSupplySize: 0.5,
      supplySizeUnit: 'mg',
      reasonForWaste: 'Dropped',
      otherComments: null,
      otherReasons: null,
      wastingTime: '2023-08-30T14:04:16.819865Z',
      wastingStatus: 'Pending',
      wasteStatusReason: '',
      witnessRequirement: 'Anytime',
      witnessType: 'Anytime',
      formularyId: null,
      color: null,
      formOfMedication: '',
      genericName: null,
      tradeName: null,
      supplyStrength: null,
      supplyStrengthUnit: null,
      wastingVideoDuration: '07:31',
    },
    {
      id: 5417,
      locationId: 411,
      locationName: 'Midas HS General',
      cartId: 705,
      cartName: 'VIEW 1',
      videoThumbnailUrl:
        'https://midas-cloud-video-storage.s3.amazonaws.com/?AWSAccessKeyId=AKIAULWL6QUYII6RUUZP&Expires=1730120961&Signature=xb6jvm0A%2FQmQR1VChIA%2FgTv9zmc%3D',
      wasteUser: {
        id: 1241,
        username: 'nurse1',
        firstName: 'Michael',
        lastName: 'LaFauci',
      },
      witnessUser: null,
      amountWasted: 0.45,
      units: 'mg',
      amountWastedInSupplySize: 0.45,
      supplySizeUnit: 'mg',
      reasonForWaste: 'Order Changed',
      otherComments: 'test comment',
      otherReasons: null,
      wastingTime: '2023-08-30T15:57:38.455662Z',
      wastingStatus: 'Pending',
      wasteStatusReason: '',
      witnessRequirement: 'Anytime',
      witnessType: 'Anytime',
      formularyId: null,
      color: null,
      formOfMedication: '',
      genericName: null,
      tradeName: null,
      supplyStrength: null,
      supplyStrengthUnit: null,
      wastingVideoDuration: '00:55',
    },
    {
      id: 5351,
      locationId: 411,
      locationName: 'Midas HS General',
      cartId: 725,
      cartName: 'MIDAS-HOSPITAL-DEMO',
      videoThumbnailUrl:
        'https://midas-cloud-video-storage.s3.amazonaws.com/?AWSAccessKeyId=AKIAULWL6QUYII6RUUZP&Expires=1730120961&Signature=xb6jvm0A%2FQmQR1VChIA%2FgTv9zmc%3D',
      wasteUser: {
        id: 1241,
        username: 'nurse1',
        firstName: 'Michael',
        lastName: 'LaFauci',
      },
      witnessUser: null,
      amountWasted: 0.7,
      units: 'mg',
      amountWastedInSupplySize: 0.7,
      supplySizeUnit: 'mg',
      reasonForWaste: 'Refused',
      otherComments: null,
      otherReasons: null,
      wastingTime: '2023-08-23T14:23:37.103283Z',
      wastingStatus: 'Pending',
      wasteStatusReason: '',
      witnessRequirement: 'Anytime',
      witnessType: 'Anytime',
      formularyId: null,
      color: null,
      formOfMedication: '',
      genericName: null,
      tradeName: null,
      supplyStrength: null,
      supplyStrengthUnit: null,
      wastingVideoDuration: '02:06',
    },
    {
      id: 5420,
      locationId: 411,
      locationName: 'Midas HS General',
      cartId: 715,
      cartName: 'MIKETEST',
      videoThumbnailUrl:
        'https://midas-cloud-video-storage.s3.amazonaws.com/?AWSAccessKeyId=AKIAULWL6QUYII6RUUZP&Expires=1730120961&Signature=xb6jvm0A%2FQmQR1VChIA%2FgTv9zmc%3D',
      wasteUser: {
        id: 1241,
        username: 'nurse1',
        firstName: 'Michael',
        lastName: 'LaFauci',
      },
      witnessUser: null,
      amountWasted: 0.35,
      units: 'mg',
      amountWastedInSupplySize: 0.35,
      supplySizeUnit: 'mg',
      reasonForWaste: 'Expected Waste',
      otherComments: 'i',
      otherReasons: null,
      wastingTime: '2023-08-31T06:46:08.310087Z',
      wastingStatus: 'Pending',
      wasteStatusReason: '',
      witnessRequirement: 'Anytime',
      witnessType: 'Anytime',
      formularyId: null,
      color: null,
      formOfMedication: '',
      genericName: null,
      tradeName: null,
      supplyStrength: null,
      supplyStrengthUnit: null,
      wastingVideoDuration: '01:02',
    },
    {
      id: 5422,
      locationId: 411,
      locationName: 'Midas HS General',
      cartId: 715,
      cartName: 'MIKETEST',
      videoThumbnailUrl:
        'https://midas-cloud-video-storage.s3.amazonaws.com/?AWSAccessKeyId=AKIAULWL6QUYII6RUUZP&Expires=1730120961&Signature=xb6jvm0A%2FQmQR1VChIA%2FgTv9zmc%3D',
      wasteUser: {
        id: 1241,
        username: 'nurse1',
        firstName: 'Michael',
        lastName: 'LaFauci',
      },
      witnessUser: null,
      amountWasted: 0.25,
      units: 'mg',
      amountWastedInSupplySize: 0.25,
      supplySizeUnit: 'mg',
      reasonForWaste: 'Refused',
      otherComments: null,
      otherReasons: null,
      wastingTime: '2023-08-31T21:17:17.962827Z',
      wastingStatus: 'Pending',
      wasteStatusReason: '',
      witnessRequirement: 'Anytime',
      witnessType: 'Anytime',
      formularyId: null,
      color: null,
      formOfMedication: '',
      genericName: null,
      tradeName: null,
      supplyStrength: null,
      supplyStrengthUnit: null,
      wastingVideoDuration: '00:39',
    },
    {
      id: 5421,
      locationId: 411,
      locationName: 'Midas HS General',
      cartId: 715,
      cartName: 'MIKETEST',
      videoThumbnailUrl:
        'https://midas-cloud-video-storage.s3.amazonaws.com/?AWSAccessKeyId=AKIAULWL6QUYII6RUUZP&Expires=1730120961&Signature=xb6jvm0A%2FQmQR1VChIA%2FgTv9zmc%3D',
      wasteUser: {
        id: 1241,
        username: 'nurse1',
        firstName: 'Michael',
        lastName: 'LaFauci',
      },
      witnessUser: null,
      amountWasted: 0.3,
      units: 'mg',
      amountWastedInSupplySize: 0.3,
      supplySizeUnit: 'mg',
      reasonForWaste: 'Order Changed',
      otherComments: null,
      otherReasons: null,
      wastingTime: '2023-08-31T13:26:12.723705Z',
      wastingStatus: 'Pending',
      wasteStatusReason: '',
      witnessRequirement: 'Anytime',
      witnessType: 'Anytime',
      formularyId: null,
      color: null,
      formOfMedication: '',
      genericName: null,
      tradeName: null,
      supplyStrength: null,
      supplyStrengthUnit: null,
      wastingVideoDuration: '00:38',
    },
    {
      id: 5508,
      locationId: 411,
      locationName: 'Midas HS General',
      cartId: 717,
      cartName: 'midasdemo',
      videoThumbnailUrl:
        'https://midas-cloud-video-storage.s3.amazonaws.com/?AWSAccessKeyId=AKIAULWL6QUYII6RUUZP&Expires=1730120961&Signature=xb6jvm0A%2FQmQR1VChIA%2FgTv9zmc%3D',
      wasteUser: {
        id: 1241,
        username: 'nurse1',
        firstName: 'Michael',
        lastName: 'LaFauci',
      },
      witnessUser: null,
      amountWasted: 0,
      units: '1',
      amountWastedInSupplySize: 0,
      supplySizeUnit: '1',
      reasonForWaste: '',
      otherComments: 'no comments',
      otherReasons: '',
      wastingTime: '2023-09-19T14:48:02.172616Z',
      wastingStatus: 'Pending',
      wasteStatusReason: '',
      witnessRequirement: 'Anytime',
      witnessType: 'Anytime',
      formularyId: null,
      color: null,
      formOfMedication: '',
      genericName: null,
      tradeName: null,
      supplyStrength: 0,
      supplyStrengthUnit: null,
      wastingVideoDuration: '03:18',
    },
    {
      id: 5505,
      locationId: 411,
      locationName: 'Midas HS General',
      cartId: 717,
      cartName: 'midasdemo',
      videoThumbnailUrl:
        'https://midas-cloud-video-storage.s3.amazonaws.com/?AWSAccessKeyId=AKIAULWL6QUYII6RUUZP&Expires=1730120961&Signature=xb6jvm0A%2FQmQR1VChIA%2FgTv9zmc%3D',
      wasteUser: {
        id: 1241,
        username: 'nurse1',
        firstName: 'Michael',
        lastName: 'LaFauci',
      },
      witnessUser: null,
      amountWasted: 0.2,
      units: 'mg',
      amountWastedInSupplySize: 0.2,
      supplySizeUnit: 'mg',
      reasonForWaste: 'Expected Waste',
      otherComments: '',
      otherReasons: null,
      wastingTime: '2023-09-18T23:52:10.126203Z',
      wastingStatus: 'Pending',
      wasteStatusReason: '',
      witnessRequirement: 'Anytime',
      witnessType: 'Anytime',
      formularyId: null,
      color: null,
      formOfMedication: '',
      genericName: null,
      tradeName: null,
      supplyStrength: null,
      supplyStrengthUnit: null,
      wastingVideoDuration: '01:57',
    },
    {
      id: 5757,
      locationId: 411,
      locationName: 'Midas HS General',
      cartId: 777,
      cartName: 'The Rup Part 2',
      videoThumbnailUrl:
        'https://midas-cloud-video-storage.s3.amazonaws.com/?AWSAccessKeyId=AKIAULWL6QUYII6RUUZP&Expires=1730120961&Signature=xb6jvm0A%2FQmQR1VChIA%2FgTv9zmc%3D',
      wasteUser: {
        id: 1241,
        username: 'nurse1',
        firstName: 'Michael',
        lastName: 'LaFauci',
      },
      witnessUser: null,
      amountWasted: 0,
      units: 'mg',
      amountWastedInSupplySize: 0,
      supplySizeUnit: 'mg',
      reasonForWaste: 'Refused',
      otherComments: 'sdf',
      otherReasons: null,
      wastingTime: '2024-02-01T13:57:43.650826Z',
      wastingStatus: 'Pending',
      wasteStatusReason: '',
      witnessRequirement: 'Anytime',
      witnessType: 'Anytime',
      formularyId: null,
      color: null,
      formOfMedication: '',
      genericName: null,
      tradeName: null,
      supplyStrength: null,
      supplyStrengthUnit: null,
      wastingVideoDuration: '00:40',
    },
  ],
};
