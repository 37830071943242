export const numberStandardWasteLocation= 
{ 
    count: 8,
    items: [
        {
            id: 673,
            locationName:'Jackson Memorial Hospital',
            amountOfWaste: Math.floor(Math.random() * 100),
        },
        {
            id: 674,
            locationName:'HCA Florida Mercy Hospital',
            amountOfWaste: Math.floor(Math.random() * 100),
        },
        {
            id: 675,
            locationName:'Mount Sinai Medical Center (Miami)',
            amountOfWaste: Math.floor(Math.random() * 100),
        },
        {
            id: 676,
            locationName:'Hospital Keralty',
            amountOfWaste: Math.floor(Math.random() * 100),
        },
        {
            id: 677,
            locationName:'Nicklaus Childrens Hospital',
            amountOfWaste: Math.floor(Math.random() * 100),
        },
        {
            id: 678,
            locationName:'Rula Health',
            amountOfWaste: Math.floor(Math.random() * 100),
        },
        {
            id: 679,
            locationName:'Sylvester Comprehensive Cancer Center',
            amountOfWaste: Math.floor(Math.random() * 100),
        },
        {
            id: 680,
            locationName:'Coral Gables Hospital',
            amountOfWaste: Math.floor(Math.random() * 100),
        },
    ]
}