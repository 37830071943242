/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { createFeature, createReducer, on } from '@ngrx/store';
import * as OverviewToolbarActions from './overview-toolbar.actions';

export const OVERVIEW_TOOLBAR_KEY = 'overviewToolbar';

export interface OverviewToolbarState {
  space: 'org' | 'overview';  
}

const initialState: OverviewToolbarState = {
  space: 'org'
};

export const OverviewToolbarFeature = createFeature({
  name: OVERVIEW_TOOLBAR_KEY,
  reducer: createReducer(
    initialState,
    on(OverviewToolbarActions.changeSpace, (state,  { data }): OverviewToolbarState => {
      return {
        ...state,
        space: data.space,
      };
    })
  ),
});
