/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.dev.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://alibi.dev.midassecure.com/api',
  defaultLang: 'en',
  useMocks: false,
  pullingTime: 60000,
  defaultViewsFilter: ['Brooklyn - Building C', 'Bronx - Building A'],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
