export const rxInterceptWaste = {
  count: 19,
  items: [
    {
      id: 1,
      center: 'Albany Medical Center',
      amountOfWaste: Math.floor(Math.random() * 100),
      date: '2024-01-20',
    },
    {
      id: 2,
      center: 'Colorado Northwell Hospital',
      amountOfWaste: Math.floor(Math.random() * 100),
      date: '2024-01-20',
    },
    {
      id: 3,
      center: 'Miami Children\'s Hospital',
      amountOfWaste: Math.floor(Math.random() * 100),
      date: '2024-01-20',
    },
    {
      id: 4,
      center: 'New York Presbyterian Hospital',
      amountOfWaste: Math.floor(Math.random() * 100),
      date: '2024-01-20',
    },
    {
      id: 5,
      center: 'Northwell Health',
      amountOfWaste: Math.floor(Math.random() * 100),
      date: '2024-01-20',
    }
  ]
}
