export const anytimeWitness= 
{
    "items": [
      {
        id: 4,
        locationId: 411,
        locationName: "Manhattan",
        cartId: 742,
        cartName: "CHUITEST",
        wastedUser: {
          id: 1241,
          username: "nurse1",
          firstName: "B",
          lastName: "Cooper",
        },
        witnessUser: {
          id: 1241,
          username: "nurse2",
          firstName: "Doug",
          lastName: "Gibson",
        },
        amountWasted: 1,
        units: "mg",
        amountWastedInSupplySize: 0.01,
        supplySizeUnit: "Injection",
        reasonForWaste: "Expected",
        otherComments: null,
        otherReasons: null,
        wastingTime: "2024-06-06T17:10:06.315746Z",
        wastingStatus: "Completed",
        wasteStatusReason: "",
        witnessRequirement: 'Anytime',
        witnessType: 'Anytime',
        formularyId: 6046,
        color: null,
        formOfMedication: 'Injection',
        genericName: 'MIDAZOLAM',
        tradeName: 'Ativan',
        supplyStrength: 1,
        supplyStrengthUnit: 'mg',
        videoThumbnailUrl: "alibi_thumbnails-4.png",
        wastingVideoDuration: '45',
      },   
      {
        id: 3,
        locationId: 411,
        locationName: "Manhattan",
        cartId: 742,
        cartName: "CHUITEST",
        wastedUser: {
          id: 1241,
          username: "nurse1",
          firstName: "B",
          lastName: "Cooper",
        },
        witnessUser: {
          id: 1241,
          username: "nurse2",
          firstName: "Doug",
          lastName: "Gibson",
        },
        amountWasted: 75,
        units: "mcg",
        amountWastedInSupplySize: 0.01,
        supplySizeUnit: "Injection",
        reasonForWaste: "Expected",
        otherComments: null,
        otherReasons: null,
        wastingTime: "2024-06-05T17:10:06.315746Z",
        wastingStatus: "Completed",
        wasteStatusReason: "",
        witnessRequirement: 'In Person',
        witnessType: 'In Person',
        formularyId: 6046,
        color: null,
        formOfMedication: 'Injection',
        genericName: 'fentaNYL',
        tradeName: 'Ativan',
        supplyStrength:50,
        supplyStrengthUnit: 'mcg',
        videoThumbnailUrl: "alibi_thumbnails-3.png",
        wastingVideoDuration: '20',
      },
      {
        id: 5,
        locationId: 411,
        locationName: "Manhattan",
        cartId: 742,
        cartName: "CHUITEST",
        wastedUser: {
          id: 1241,
          username: "nurse1",
          firstName: "N",
          lastName: "SMith",
        },
        witnessUser: {
          id: 1241,
          username: "nurse2",
          firstName: "Doug",
          lastName: "Gibson",
        },
        amountWasted: 2.5,
        units: "mg",
        amountWastedInSupplySize: 0.01,
        supplySizeUnit: "Tablet",
        reasonForWaste: "dose = 1/2 tab",
        otherComments: null,
        otherReasons: null,
        wastingTime: "2024-06-05T17:10:06.315746Z",
        wastingStatus: "Completed",
        wasteStatusReason: "",
        witnessRequirement: 'Anytime',
        witnessType: 'Anytime',
        formularyId: 6046,
        color: null,
        formOfMedication: 'Tablet',
        genericName: 'oxyCODONE',
        tradeName: 'Ativan',
        supplyStrength: 5,
        supplyStrengthUnit: 'mg',
        videoThumbnailUrl: "alibi_thumbnails-5.jpeg",
        wastingVideoDuration: '20',
      },
      {
        id: 6,
        locationId: 411,
        locationName: "Manhattan",
        cartId: 742,
        cartName: "CHUITEST",
        wastedUser: {
          id: 1241,
          username: "nurse1",
          firstName: "A",
          lastName: "Fallon",
        },
        witnessUser: {
          id: 1241,
          username: "nurse2",
          firstName: "Doug",
          lastName: "Gibson",
        },
        amountWasted: 1,
        units: "mg",
        amountWastedInSupplySize: 0.01,
        supplySizeUnit: "Injection",
        reasonForWaste: "Expected",
        otherComments: null,
        otherReasons: null,
        wastingTime: "2024-06-05T17:10:06.315746Z",
        wastingStatus: "Completed",
        wasteStatusReason: "",
        witnessRequirement: 'In Person',
        witnessType: 'In Person',
        formularyId: 6046,
        color: null,
        formOfMedication: 'Injection',
        genericName: 'MORPHINE',
        tradeName: 'Ativan',
        supplyStrength: 2,
        supplyStrengthUnit: 'mg',
        videoThumbnailUrl: "alibi_thumbnails-6.jpeg",
        wastingVideoDuration: '32',
      },
      {
        id: 7,
        locationId: 411,
        locationName: "Manhattan",
        cartId: 742,
        cartName: "CHUITEST",
        wastedUser: {
          id: 1241,
          username: "nurse1",
          firstName: "N",
          lastName: "Smith",
        },
        witnessUser: {
          id: 1241,
          username: "nurse2",
          firstName: "Doug",
          lastName: "Gibson",
        },
        amountWasted: 0.4,
        units: "mg",
        amountWastedInSupplySize: 0.01,
        supplySizeUnit: "Injection",
        reasonForWaste: "Expected",
        otherComments: null,
        otherReasons: null,
        wastingTime: "2024-06-05T17:10:06.315746Z",
        wastingStatus: "Completed",
        wasteStatusReason: "",
        witnessRequirement: 'Anytime',
        witnessType: 'Anytime',
        formularyId: 6046,
        color: null,
        formOfMedication: 'Injection',
        genericName: 'HYDROmorphone',
        tradeName: 'Ativan',
        supplyStrength: 2,
        supplyStrengthUnit: 'mg',
        videoThumbnailUrl: "alibi_thumbnails-7.jpeg",
        wastingVideoDuration: '55',
      },
      {
        id: 8,
        locationId: 411,
        locationName: "Manhattan",
        cartId: 742,
        cartName: "CHUITEST",
        wastedUser: {
          id: 1241,
          username: "nurse1",
          firstName: "N",
          lastName: "Smith",
        },
        witnessUser: {
          id: 1241,
          username: "nurse2",
          firstName: "Doug",
          lastName: "Gibson",
        },
        amountWasted: 50,
        units: "mg",
        amountWastedInSupplySize: 0.01,
        supplySizeUnit: "Injection",
        reasonForWaste: "Expected",
        otherComments: null,
        otherReasons: null,
        wastingTime: "2024-06-05T17:10:06.315746Z",
        wastingStatus: "Completed",
        wasteStatusReason: "",
        witnessRequirement: 'Anytime',
        witnessType: 'Anytime',
        formularyId: 6046,
        color: null,
        formOfMedication: 'Injection',
        genericName: 'PROPOFOL',
        tradeName: 'Ativan',
        supplyStrength: 10,
        supplyStrengthUnit: 'mg',
        videoThumbnailUrl: "alibi_thumbnails-8.jpeg",
        wastingVideoDuration: '235',
      },
      {
        id: 9,
        locationId: 411,
        locationName: "Manhattan",
        cartId: 742,
        cartName: "CHUITEST",
        wastedUser: {
          id: 1241,
          username: "nurse1",
          firstName: "B",
          lastName: "Cooper",
        },
        witnessUser: {
          id: 1241,
          username: "nurse2",
          firstName: "Doug",
          lastName: "Gibson",
        },
        amountWasted: 1,
        units: "patch",
        amountWastedInSupplySize: 0.01,
        supplySizeUnit: "Patch",
        reasonForWaste: "Expected",
        otherComments: null,
        otherReasons: null,
        wastingTime: "2024-06-05T17:10:06.315746Z",
        wastingStatus: "Completed",
        wasteStatusReason: "",
        witnessRequirement: 'In Person',
        witnessType: 'In Person',
        formularyId: 6046,
        color: null,
        formOfMedication: 'Patch',
        genericName: 'fentaNYL Patch',
        tradeName: 'Ativan',
        supplyStrength: 25,
        supplyStrengthUnit: 'mcg',
        videoThumbnailUrl: "alibi_thumbnails-1.jpeg",
        wastingVideoDuration: '34',
      },
      {
        id: 2,
        locationId: 411,
        locationName: "Manhattan",
        cartId: 742,
        cartName: "CHUITEST",
        wastedUser: {
          id: 1241,
          username: "nurse1",
          firstName: "N",
          lastName: "Smith",
        },
        witnessUser: {
          id: 1241,
          username: "nurse2",
          firstName: "Doug",
          lastName: "Gibson",
        },
        amountWasted: 1,
        units: "mg",
        amountWastedInSupplySize: 0.01,
        supplySizeUnit: "tablet",
        reasonForWaste: "Expected",
        otherComments: null,
        otherReasons: null,
        wastingTime: "2024-01-04T17:10:06.315746Z",
        wastingStatus: "Completed",
        wasteStatusReason: "",
        witnessRequirement: 'Anytime',
        witnessType: 'Anytime',
        formularyId: 6046,
        color: null,
        formOfMedication: 'tablet',
        genericName: 'LORazepam',
        tradeName: 'Ativan',
        supplyStrength: 2,
        supplyStrengthUnit: 'mg',
        videoThumbnailUrl: "alibi_thumbnails-2.png",
        wastingVideoDuration: '40',
      },
    ]
  }