export * from './views.mock';
export * from './number-of-waste.mock';
export * from './top-ten-waste-by-person';
export * from './top-ten-waste-by-drug';
export * from './coordinates.mock';
export * from './number-of-witness.mock';
export * from './number-of-waste-by-hospital.mock';
export * from './number-of-witness-by-hospital.mock';
export * from './rxInterceptWaste.mock';
export * from './number-of-standard-waste.mock';
export * from './number-of-standard-waste-location.mock'
export * from './number-of-rxIntercept-by-date-range.mock';
export * from './anytimeWitness.mock';
export * from './overviewViewsLocations.mock';
