export const coordinates: any = {
  locations: [
    {
      id: 1,
      name: 'Albany Medical Center',
      coordinates: {
        latitude: 40.75563033792257,
        longitude: -73.70749593609143
      },
      status: 'ok'
    },
    {
      id: 2,
      name: 'Manhattan Medical Center',
      coordinates: {
        latitude: 44.16615,
        longitude: -74.024952
      },
      status: 'ok'
    },
    {
      id: 3,
      name: 'New York Presbyterian Hospital',
      coordinates: {
        latitude: 43.16615,
        longitude: -76.024952
      },
      status: 'ok'
    },
    {
      id: 4,
      name: 'Colorado Northwell Hospital',
      coordinates: {
        latitude: 39.7392,
        longitude: -104.9903
      },
      status: 'ok'
    },
    {
      id: 5,
      name: 'Texas Medical Center',
      coordinates: {
        latitude: 29.7604,
        longitude: -95.3698
      },
      status: 'ok'
    },
    {
      id: 6,
      name: 'California Medical Center',
      coordinates: {
        latitude: 34.0522,
        longitude: -118.2437
      },
      status: 'ok'
    },
    {
      id: 7,
      name: 'Dallas Medical Center',
      coordinates: {
        latitude: 32.7767,
        longitude: -96.7969
      },
      status: 'ok'
    },
    {
      id: 8,
      name: 'Montana Medical Center',
      coordinates: {
        latitude: 47.053573,
        longitude: -110.362565
      },
      status: 'ok'
    },
    {
      id: 9,
      name: 'Washington Medical Center',
      coordinates: {
        latitude: 47.6062,
        longitude: -122.3321
      },
      status: 'ok'
    },
    {
      id: 10,
      name: 'Iowa Medical Center',
      coordinates: {
        latitude: 41.878,
        longitude: -93.6208
      },
      status: 'error'
    },
    {
      id: 11,
      name: 'Oregon Medical Center',
      coordinates: {
        latitude: 44.0521,
        longitude: -122.3321
      },
      status: 'warning'
    },
    {
      id: 12,
      name: 'Oregon Medical Center',
      coordinates: {
        latitude: 47.0521,
        longitude: -122.3321
      },
      status: 'warning'
    },
    {
      id: 13,
      name: 'North Shore University Hospital',
      coordinates: {
        latitude: 40.77573092545342,
        longitude: -73.7013446859734
      },
      status: 'warning'
    },
    {
      id: 14,
      name: 'Staten Island University Hospital - North Campus',
      coordinates: {
        latitude: 40.58521196997248,
        longitude: -74.08530945918977
      },
      status: 'error'
    },
    {
      id: 15,
      name: 'Northwell Health',
      coordinates: {
        latitude: 40.740983237521235,
        longitude: -73.64106940402844
      },
      status: 'error'
    },
    {
      id: 16,
      name: 'OCIO Northwell Health',
      coordinates: {
        latitude: 40.762000231565075,
        longitude: -73.6907713859598
      },
      status: 'ok'
    },
    {
      id: 17,
      name: 'Northwell Health System',
      coordinates: {
        latitude: 40.78189020741247,
        longitude: -73.70697769878934
      },
      status: 'ok'
    },
    {
      id: 18,
      name: 'LIJ Northwell',
      coordinates: {
        latitude: 40.75456229063849,
        longitude: -73.707641705605
      },
      status: 'ok'
    },
    {
      id: 19,
      name: 'Northwell Health at  \n 59th Street',
      coordinates: {
        latitude: 40.7623656669086,
        longitude: -73.96916795145728
      },
      status: 'ok'
    },
    {
      id: 20,
      name: 'Northwell Health Physician Partners  \n at Rego Park',
      coordinates: {
        latitude: 40.730830256467144,
        longitude: -73.86313719387938
      },
      status: 'warning'
    },
    {
      id: 21,
      name: 'Southside Hospital Northwell Health',
      coordinates: {
        latitude: 40.727848499113094,
        longitude: -73.24267752393945
      },
      status: 'warning'
    },
    {
      id: 22,
      name: 'Northwell Health BEST Healthcare Center',
      coordinates: {
        latitude: 40.70952042141224,
        longitude: -73.72053862946166
      },
      status: 'warning'
    },
    {
      id: 23,
      name: 'Long Island Jewish Valley Stream',
      coordinates: {
        latitude: 40.68113411805473,
        longitude: -73.68641454664049
      },
      status: 'warning'
    },
    {
      id: 24,
      name: 'Neurosurgery at North Shore \n University Hospital',
      coordinates: {
        latitude: 40.77787204505257,
        longitude: -73.70162638835718
      },
      status: 'ok'
    },
    {
      id: 25,
      name: 'Cohen Children\'s Medical Center',
      coordinates: {
        latitude: 40.7535857,
        longitude: -73.7083463,
      },
      status: 'ok'
    },
    {
      id: 26,
      name: 'University Hospital - Newark',
      coordinates: {
        latitude: 40.73989659956143,
        longitude: -74.18828093619547,
      },
      status: 'ok'
    },
    {
      id: 27,
      name: 'The Mount Sinai Medical Center',
      coordinates: {
        latitude: 40.79015247738716,
        longitude: -73.95246601812987
      },
      status: 'ok'
    }
  ]
}