export const mockedorganization = {
  id: 1,
  name: 'MIDAS',
  midasAdminOrganizationPermissions: 2,
  cartAutoLogoutTime: 30,
  createdAt: '2022-11-10T16:11:00Z',
  updatedAt: '2024-02-27T22:08:35.396501Z',
  address: null,
  city: null,
  state: null,
  zip: null,
  contactName: null,
  contactPhone: null,
  contactEmail: null,
  accountNumber: '000000000',
  rootUser: {
    id: 1,
    userId: '00000000-0000-0000-0000-000000000000',
    username: 'admin',
    firstName: null,
    lastName: null,
    midasAdmin: true,
    permissions: 63,
    createdAt: '2022-11-10T16:11:00Z',
    lastAccessed: '2024-03-01T19:05:00.480348Z',
    userGroups: [1],
  },
};
