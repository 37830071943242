export const locationsMock = [
  {
    id: 2,
    name: 'IQ Testing (Root)',
    description: null,
    latitude: null,
    longitude: null,
    parentId: null,
    parentName: null,
    carts: 0,
    userGroups: 1,
  },
  {
    id: 3,
    name: 'IQ Testing',
    description: '',
    latitude: -76.024952,
    longitude: 43.16615,
    parentId: 2,
    parentName: 'IQ Testing (Root)',
    carts: 1,
    userGroups: 4,
  },
  {
    id: 4,
    name: 'Andrew Hospital',
    description: null,
    latitude: null,
    longitude: null,
    parentId: 3,
    parentName: 'IQ Testing',
    carts: 0,
    userGroups: 1,
  },
  {
    id: 5,
    name: 'Anthony Hospital',
    description: null,
    latitude: null,
    longitude: null,
    parentId: 3,
    parentName: 'IQ Testing',
    carts: 1,
    userGroups: 1,
  },
  {
    id: 6,
    name: 'April Hospital',
    description: null,
    latitude: null,
    longitude: null,
    parentId: 3,
    parentName: 'IQ Testing',
    carts: 2,
    userGroups: 3,
  },
  {
    id: 7,
    name: 'Chui Hospital',
    description: null,
    latitude: null,
    longitude: null,
    parentId: 3,
    parentName: 'IQ Testing',
    carts: 1,
    userGroups: 1,
  },
  {
    id: 8,
    name: 'Jeremy Hospital',
    description: '',
    latitude: null,
    longitude: null,
    parentId: 3,
    parentName: 'IQ Testing',
    carts: 0,
    userGroups: 1,
  },
  {
    id: 9,
    name: 'Joe Hospital',
    description: null,
    latitude: null,
    longitude: null,
    parentId: 3,
    parentName: 'IQ Testing',
    carts: 0,
    userGroups: 1,
  },
  {
    id: 10,
    name: 'Scott Hospital',
    description: 'Previously Karen Hospital',
    latitude: null,
    longitude: null,
    parentId: 3,
    parentName: 'IQ Testing',
    carts: 0,
    userGroups: 2,
  },
  {
    id: 11,
    name: 'Roger Hospital',
    description: null,
    latitude: null,
    longitude: null,
    parentId: 3,
    parentName: 'IQ Testing',
    carts: 1,
    userGroups: 1,
  },
  {
    id: 12,
    name: 'Rupert Hospital',
    description: null,
    latitude: null,
    longitude: null,
    parentId: 3,
    parentName: 'IQ Testing',
    carts: 0,
    userGroups: 1,
  },
  {
    id: 13,
    name: 'Jarod Hospital',
    description: null,
    latitude: null,
    longitude: null,
    parentId: 3,
    parentName: 'IQ Testing',
    carts: 0,
    userGroups: 1,
  },
  {
    id: 14,
    name: 'Shared Hospital',
    description: '',
    latitude: -76.024952,
    longitude: 43.16615,
    parentId: 3,
    parentName: 'IQ Testing',
    carts: 2,
    userGroups: 1,
  },
  {
    id: 16,
    name: 'East',
    description: null,
    latitude: null,
    longitude: null,
    parentId: 3,
    parentName: 'IQ Testing',
    carts: 0,
    userGroups: 0,
  },
  {
    id: 17,
    name: 'ICU',
    description: null,
    latitude: null,
    longitude: null,
    parentId: 16,
    parentName: 'East',
    carts: 0,
    userGroups: 0,
  },
  {
    id: 18,
    name: 'Room 1',
    description: '',
    latitude: null,
    longitude: null,
    parentId: 17,
    parentName: 'ICU',
    carts: 0,
    userGroups: 0,
  },
  {
    id: 19,
    name: 'Jacob Hospital',
    description: null,
    latitude: null,
    longitude: null,
    parentId: 3,
    parentName: 'IQ Testing',
    carts: 0,
    userGroups: 1,
  },
  {
    id: 20,
    name: 'Bob Hospital',
    description: null,
    latitude: null,
    longitude: null,
    parentId: 3,
    parentName: 'IQ Testing',
    carts: 0,
    userGroups: 1,
  },
  {
    id: 23,
    name: 'Mark Hospital',
    description: null,
    latitude: null,
    longitude: null,
    parentId: 3,
    parentName: 'IQ Testing',
    carts: 0,
    userGroups: 1,
  },
  {
    id: 417,
    name: 'April 2.0',
    description: null,
    latitude: null,
    longitude: null,
    parentId: 6,
    parentName: 'April Hospital',
    carts: 0,
    userGroups: 4,
  },
  {
    id: 435,
    name: 'FF Hospital',
    description: null,
    latitude: -74.024952,
    longitude: 44.16615,
    parentId: 2,
    parentName: 'IQ Testing (Root)',
    carts: 0,
    userGroups: 0,
  },
];
