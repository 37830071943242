export const numberWitnessByHospital = 
{ 
    count: 38,
    items: [
        {
            id: 673,
            hospital:'ACMH Hospital',
            amountOfWaste: Math.floor(Math.random() * 100),
            date: '2024-01-20'
        },
        {
            id: 674,
            hospital:'Abbeville General Hospital',
            amountOfWaste: Math.floor(Math.random() * 100),
            date: '2024-02-20'
        },
        {
            id: 675,
            hospital:'Bayhealth Kent Campus',
            amountOfWaste: Math.floor(Math.random() * 100),
            date: '2024-03-20'
        },
        {
            id: 676,
            hospital:'Cambridge Medical Center',
            amountOfWaste: Math.floor(Math.random() * 100),
            date: '2024-01-20'
        },
        {
            id: 677,
            hospital:'ECU Health North Hospital',
            amountOfWaste: Math.floor(Math.random() * 100),
            date: '2024-02-20'
        },
        {
            id: 678,
            hospital:'Frederick Health Hospital',
            amountOfWaste: Math.floor(Math.random() * 100),
            date: '2024-03-20'
        },
    ]
}