import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, retry, throwError } from 'rxjs';

interface QueryParams {
  [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean> | undefined | null;
}

@Injectable({
  providedIn: 'root'
})
export class MemoryApiService {

  private apiUrl = 'api/';

  constructor(private http: HttpClient) {}

  get<T>(path: string) {
    return this.http.get<T[]>(`${this.apiUrl}${path}`).pipe(
      retry(2),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        return throwError(error);
      })
    );
  }

}
