export const coordinates: any = {
  locations: [
    {
      id: 1,
      name: 'Albany Medical Center',
      coordinates: {
        latitude: -75.024952,
        longitude: 42.16615
      },
      status: 'ok'
    },
    {
      id: 2,
      name: 'Manhattan Medical Center',
      coordinates: {
        latitude: -74.024952,
        longitude: 44.16615
      },
      status: 'ok'
    },
    {
      id: 3,
      name: 'New York Presbyterian Hospital',
      coordinates: {
        latitude: -76.024952,
        longitude: 43.16615
      },
      status: 'ok'
    },
    {
      id: 4,
      name: 'Colorado Northwell Hospital',
      coordinates: {
        latitude: -104.9903,
        longitude: 39.7392
      },
      status: 'ok'
    },
    {
      id: 5,
      name: 'Texas Medical Center',
      coordinates: {
        latitude: -95.3698,
        longitude: 29.7604
      },
      status: 'ok'
    },
    {
      id: 6,
      name: 'California Medical Center',
      coordinates: {
        latitude: -118.2437,
        longitude: 34.0522
      },
      status: 'ok'
    },
    {
      id: 7,
      name: 'Dallas Medical Center',
      coordinates: {
        latitude: -96.7969,
        longitude: 32.7767
      },
      status: 'ok'
    },
    {
      id: 8,
      name: 'Montana Medical Center',
      coordinates: {
        latitude: -110.362565,
        longitude: 47.053573
      },
      status: 'ok'
    },
    {
      id: 9,
      name: 'Washington Medical Center',
      coordinates: {
        latitude: -122.3321,
        longitude: 47.6062
      },
      status: 'ok'
    },
    {
      id: 10,
      name: 'Iowa Medical Center',
      coordinates: {
        latitude: -93.6208,
        longitude: 41.878
      },
      status: 'error'
    },
    {
      id: 11,
      name: 'Oregon Medical Center',
      coordinates: {
        latitude: -122.3321,
        longitude: 44.0521
      },
      status: 'warning'
    },
    {
      id: 12,
      name: 'Oregon Medical Center',
      coordinates: {
        latitude: -122.3321,
        longitude: 47.0521
      },
      status: 'warning'
    },
    {
      id: 13,
      name: 'North Shore University Hospital',
      coordinates: {
        latitude: -73.7013446859734,
        longitude: 40.77573092545342
      },
      status: 'warning'
    },
    {
      id: 14,
      name: 'Staten Island University Hospital - North Campus',
      coordinates: {
        latitude: -74.08530945918977,
        longitude: 40.58521196997248
      },
      status: 'error'
    },
    {
      id: 15,
      name: 'Northwell Health',
      coordinates: {
        latitude: -73.64106940402844,
        longitude: 40.740983237521235
      },
      status: 'error'
    },
    {
      id: 16,
      name: 'OCIO Northwell Health',
      coordinates: {
        latitude: -73.6907713859598,
        longitude: 40.762000231565075
      },
      status: 'ok'
    },
    {
      id: 17,
      name: 'Northwell Health System',
      coordinates: {
        latitude: -73.70697769878934,
        longitude: 40.78189020741247
      },
      status: 'ok'
    },
    {
      id: 18,
      name: 'LIJ Northwell',
      coordinates: {
        latitude: -73.707641705605,
        longitude: 40.75456229063849
      },
      status: 'ok'
    },
    {
      id: 19,
      name: 'Northwell Health at  \n 59th Street',
      coordinates: {
        latitude: -73.96916795145728,
        longitude: 40.7623656669086
      },
      status: 'ok'
    },
    {
      id: 20,
      name: 'Northwell Health Physician Partners  \n at Rego Park',
      coordinates: {
        latitude: -73.86313719387938,
        longitude: 40.730830256467144
      },
      status: 'warning'
    },
    {
      id: 21,
      name: 'Southside Hospital Northwell Health',
      coordinates: {
        latitude: -73.24267752393945,
        longitude: 40.727848499113094
      },
      status: 'warning'
    },
    {
      id: 22,
      name: 'Northwell Health BEST Healthcare Center',
      coordinates: {
        latitude: -73.72053862946166,
        longitude: 40.70952042141224
      },
      status: 'warning'
    },
    {
      id: 23,
      name: 'Long Island Jewish Valley Stream',
      coordinates: {
        latitude: -73.68641454664049,
        longitude: 40.68113411805473
      },
      status: 'warning'
    },
    {
      id: 24,
      name: 'Neurosurgery at North Shore \n University Hospital',
      coordinates: {
        latitude: -73.70162638835718,
        longitude: 40.77787204505257
      },
      status: 'ok'
    },
    {
      id: 25,
      name: 'Cohen Children\'s Medical Center',
      coordinates: {
        latitude: -73.7083463,
        longitude: 40.7535857
      },
      status: 'ok'
    },
    {
      id: 26,
      name: 'University Hospital - Newark',
      coordinates: {
        latitude: -74.18828093619547,
        longitude: 40.73989659956143
      },
      status: 'ok'
    },
    {
      id: 27,
      name: 'The Mount Sinai Medical Center',
      coordinates: {
        latitude: -73.95246601812987,
        longitude: 40.79015247738716
      },
      status: 'ok'
    }
  ]
}