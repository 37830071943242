export const overviewViewsLocations = {
  locations: [
    {
      id: 1,
      name: 'Manhattan - Building A',
    },
    {
      id: 2,
      name: 'Manhattan - Building B',
    },
    {
      id: 3,
      name: 'Manhattan - Building C',
    },
    {
      id: 4,
      name: 'Brooklyn - Building A',
    },
    {
      id: 5,
      name: 'Brooklyn - Building B',
    },
    {
      id: 6,
      name: 'Brooklyn - Building C',
    },
    {
      id: 7,
      name: 'Bronx - Building A',
    },
    {
      id: 8,
      name: 'Bronx - Building B',
    },
    {
      id: 9,
      name: 'Bronx - Building C',
    },
  ]
}
