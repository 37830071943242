import { Injectable } from '@angular/core';
import { InMemoryDbService, RequestInfoUtilities, ParsedRequestUrl } from 'angular-in-memory-web-api';
import {
  overview_views,
  numberOfWaste,
  topTenWasteByPerson,
  topTenWasteByDrug,
  coordinates,
  numberOfWitness,
  numberWasteByHospital,
  numberWitnessByHospital,
  rxInterceptWaste,
  numberOfStandardWaste,
  numberStandardWasteLocation,
  rxInterceptWasteByDateRange,
  anytimeWitness,
  overviewViewsLocations
} from '../mocks';
import { user } from '../mocks/user.mock';
import { mockedpermissions } from '../mocks/location-permissions.mock';
import { mockedorganization } from '../mocks/organization.mock';

@Injectable({
  providedIn: 'root',
})
export class InMemoryWebApiService implements InMemoryDbService {
  parseRequestUrl(url: string, utils: RequestInfoUtilities): ParsedRequestUrl {
    const newUrl = url
      .replace('/user/location-permissions', '/mockedpermissions')
      .replace('/organization/current', '/mockedorganization')
      .replace('/overview/anytimeWitness', '/anytimeWitness')
      .replace('/overview/views/locations', '/overviewViewsLocations')
      .replace('/overview/views/', '/overview_views')
      .replace('/overview/locations/get-coordinates', '/coordinates')
      .replace('/overview/statistics/number-of-waste', '/numberOfWaste')
      .replace('/overview/statistics/hospital-waste', '/numberWasteByHospital')
      .replace('/overview/statistics/standard-waste-location', '/numberStandardWasteLocation')
      .replace('/overview/statistics/standard-waste', '/numberOfStandardWaste')
      .replace('/overview/statistics/number-of-anytime-witness-location', '/numberWitnessByHospital')
      .replace('/overview/statistics/number-of-anytime-witness', '/numberOfWitness')
      .replace('/overview/statistics/number-of-rxintercept-waste-location', '/rxInterceptWaste')
      .replace('/overview/statistics/number-of-rxintercept-waste', '/rxInterceptWasteByDateRange')
      .replace('/overview/statistics/waste-by-person', '/topTenWasteByPerson')
      .replace('/overview/statistics/waste-by-drug', '/topTenWasteByDrug')
    const parsed = utils.parseRequestUrl(newUrl);
    parsed.query = new Map();
    return parsed;
  }

  createDb() {
    return {
      overview_views,
      overviewViewsLocations,
      user,
      mockedpermissions,
      mockedorganization,
      numberOfWaste,
      topTenWasteByPerson,
      topTenWasteByDrug,
      coordinates,
      numberOfWitness,
      numberWasteByHospital,
      numberWitnessByHospital,
      rxInterceptWaste,
      numberOfStandardWaste,
      numberStandardWasteLocation,
      rxInterceptWasteByDateRange,
      anytimeWitness
    };
  }
}


