import { IOverviewStyleAndIconInfo, OverviewView } from './overview-view.model';
import { title } from 'process';

export enum EOverviewActionGroupingType {
  ERROR = 'ERROR',
  FULL = 'FULL',
  LOCKBOXFULL = 'LOCKBOXFULL',
  READYFORREVIEW = 'READYFORREVIEW',
}

export interface IOverviewAction extends OverviewView {
  error: boolean;
  readyForReview: boolean;
}

export interface IOverviewActionGroup {
  building: string;
  actions: IOverviewAction[];
}

export interface IOverviewActionWithStyle extends IOverviewAction {
  styles: IOverviewStyleAndIconInfo;
  cardNumber: string;
}

export interface IOverviewActionList {
  title: string;
  type: EOverviewActionGroupingType;
  show: boolean;
  list: IOverviewActionWithStyle[];
}

export interface IOverviewActionsFilter {
  searchValue: string;
  statusIds: string[];
  locationIds: number[];
}

export interface IActionSelectOptions {
  value: EOverviewActionGroupingType;
  label: string;
}
