/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
export enum LocationPermissions {
  /* Direct location access */

  /** Allowed at the location at which the user is part of the user group */
  CanWaste = 1,

  /** Allowed at the location at which the user is part of the user group */
  CanWitness = 2,

  /** Allowed at the location at which the user is part of the user group */
  CanViewHistoricalWastes = 4,

  /** Allowed at the location at which the user is part of the user group */
  CanEmptyBins = 8,

  /** Allowed at the location at which the user is part of the user group */
  CanManageCarts = 16,

  /* Children locations access only */

  /** Allowed only at the children locations at which the user is part of the user group */
  CanManageLocations = 32,

  /** Allowed only at the children locations at which the user is part of the user group */
  CanManageUserGroups = 64,

  /* Direct location access */

  /** Allowed at the location at which the user is part of the user group */
  CanResolve = 128,

  /** Allowed to transport the collected Rx Intercepts to Central Pharmacy */
  CanTransport = 256,

  /** Allowed to final waste an RxIntercept in Central Pharmacy */
  CanFinalWaste = 512,

  /** Allowed to mark a previously collected (and transported) sample for Analysis */
  CanSendForAnalysis = 1024,

  /** Allowed to witness transports */
  CanWitnessTransport = 2048,

  /** Allowed to view historical transports */
  CanViewHistoricalTransport = 4096,

  /** Allowed to resolve the transport records */
  CanResolveTransport = 8192,

  /** Allowed Access to Control Zone */ 
  CanAccessToControlZone = 131072,

  Root = 16383,
}
