/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
export enum EControlZonePermissions {
  /** Allowed to view the Carts Module */
  CanViewCarts = 1,

  /** Allowed to view the Anytime Witness Module */
  CanViewAnytimeWitness = 2,

  /** Allowed to view the Locations Module */
  CanViewLocations = 4,

  /** Allowed to view the Statistics Module */
  CanViewStatistics = 8,

  /** Allowed to view the Call to Action Module */
  CanViewCallToAction = 16,
}
