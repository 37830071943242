export const user = {
  id: 1,
  userId: '00000000-0000-0000-0000-000000000000',
  username: 'admin',
  lastName: null,
  firstName: null,
  email: null,
  contact: null,
  midasAdmin: true,
  permissions: 63,
  rootUser: true,
  userGroups: [
    {
      id: 1,
      name: 'Root',
      permissions: 16383,
      locationId: 1,
      locationName: 'Root',
      parentLocationId: null,
      parentLocationName: null,
      totalUsers: 1,
      createdAt: '2022-11-10T16:11:00Z',
      updatedAt: null,
    },
    {
      id: 2,
      name: 'Control Zone Group',
      permissions: 131072,
      controlZonePermissions: 31,
      locationId: 1,
      locationName: 'Root',
      parentLocationId: null,
      parentLocationName: null,
      totalUsers: 1,
      createdAt: '2022-11-10T16:11:00Z',
      updatedAt: null,
    },
  ],
  organizationId: 1,
  organizationName: 'MIDAS',
  mfaEnabled: false,
};
