/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {
  APP_INITIALIZER,
  ENVIRONMENT_INITIALIZER,
  Injectable,
  enableProdMode,
  importProvidersFrom,
  inject,
} from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material/tooltip';
import { bootstrapApplication } from '@angular/platform-browser';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { Routes, provideRouter, withRouterConfig } from '@angular/router';
import { API_URL, InitLoaderService, MidasEffectError, provideSharedCommon } from '@midas/shared/common';
import { BreadcrumbsService, provideSharedDataAccess } from '@midas/shared/data-access';
import { IdleTimerService } from '@midas/shared/ui';
import { UtilConfirmDialogService } from '@midas/shared/util';
import {
  TRANSLOCO_CONFIG,
  TRANSLOCO_LOADER,
  Translation,
  TranslocoLoader,
  TranslocoModule,
  TranslocoService,
  translocoConfig,
} from '@ngneat/transloco';
import { provideEffects } from '@ngrx/effects';
import { StoreRouterConnectingModule, provideRouterStore, routerReducer } from '@ngrx/router-store';
import { ActionReducer, USER_PROVIDED_META_REDUCERS, provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { LOADING_BAR_CONFIG } from '@ngx-loading-bar/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { provideNgxMask } from 'ngx-mask';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { InMemoryWebApiService } from './in-memory-web-api/in-memory-web-api.service';
import { NgxEchartsModule } from 'ngx-echarts';
if (environment.production) {
  enableProdMode();
}

const ngrxDebugFactory = <T>() => {
  return (reducer: ActionReducer<T>): ActionReducer<T> => {
    return (state, action) => {
      const result = reducer(state, action);
      if (action.type.includes('@ngrx/') || action.type.includes('[Ui] Clear')) {
        return result;
      }
      console.groupCollapsed(action.type);
      console.log('prev state', state);
      console.log('action', action);
      console.log('next state', result);
      console.groupEnd();
      return result;
    };
  };
};

const ngrxErrorFactory = <T>(dialog: UtilConfirmDialogService, transloco: TranslocoService) => {
  return (reducer: ActionReducer<T>): ActionReducer<T> => {
    return (state, action) => {
      const act = action as unknown as MidasEffectError;
      const result = reducer(state, action);
      if (act.showInDialog) {
        dialog.open(
          {
            title: act?.error?.error?.title || transloco.translate('Unknown Error'),
            message: act?.error?.error?.detail || transloco.translate('An unknown error occurred.'),
            buttons: [{ text: transloco.translate('OK'), color: 'primary' }],
          },
          true,
        );
      }
      return result;
    };
  };
};

const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 500,
  hideDelay: 100,
  touchendHideDelay: 500,
};

@Injectable({ providedIn: 'root' })
class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string) {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
  }
}

function preloadLang(transloco: TranslocoService) {
  return function () {
    return transloco.load(transloco.getDefaultLang()).toPromise();
  };
}

const APP_ROOT_ROUTES: Routes = [
  {
    path: '',
    loadChildren: () => import('@midas/main-shell').then((m) => m.MAIN_SHELL_ROUTES),
  },
  { path: '**', pathMatch: 'full', redirectTo: '' },
];

bootstrapApplication(AppComponent, {
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom([
      TranslocoModule,
      MatDialogModule,
      StoreRouterConnectingModule.forRoot(),
      LoadingBarHttpClientModule,
      MatNativeDateModule,
      NgxMatNativeDateModule,
      BrowserAnimationsModule,
      MatSnackBarModule,
      environment.useMocks
        ? HttpClientInMemoryWebApiModule.forRoot(InMemoryWebApiService, {
            dataEncapsulation: false,
            passThruUnknownUrl: true,
            delay: 700,
          })
        : [],
      NgxEchartsModule.forRoot({
        echarts: () => import('echarts'),
      }),
    ]),
    provideNgxMask(),

    provideRouter(
      APP_ROOT_ROUTES,
      // withDebugTracing(),
      withRouterConfig({ paramsInheritanceStrategy: 'always' }),
    ),
    provideAnimations(),
    provideEffects([]),
    provideRouterStore(),
    provideStore(
      { router: routerReducer },
      {
        runtimeChecks: environment.production
          ? undefined
          : {
              strictActionTypeUniqueness: true,
              strictActionImmutability: true,
              strictStateImmutability: true,
              strictActionWithinNgZone: true,
            },
      },
    ),
    provideSharedDataAccess(),
    provideSharedCommon(),
    ...(environment.production ? [] : [provideStoreDevtools()]),
    {
      provide: USER_PROVIDED_META_REDUCERS,
      useFactory: (confirm: UtilConfirmDialogService, transloco: TranslocoService) => [
        ngrxErrorFactory(confirm, transloco),
        ...(environment.production ? [] : [ngrxDebugFactory()]),
      ],
      deps: [UtilConfirmDialogService, TranslocoService],
    },
    {
      provide: API_URL,
      useValue: environment.production ? location.origin + '/api' : environment.apiUrl,
    },
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: ['en'],
        defaultLang: environment.defaultLang,
        reRenderOnLangChange: false,
        prodMode: environment.production,
      }),
    },
    {
      provide: TRANSLOCO_LOADER,
      useClass: TranslocoHttpLoader,
    },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: myCustomTooltipDefaults,
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: preloadLang,
      deps: [TranslocoService],
    },
    { provide: LOADING_BAR_CONFIG, useValue: { latencyThreshold: 200 } },
    InitLoaderService,
    IdleTimerService,
    {
      provide: ENVIRONMENT_INITIALIZER,
      multi: true,
      useFactory: () => () => {
        inject(InitLoaderService).load();
        inject(IdleTimerService).initializeIdleTimer();
        inject(BreadcrumbsService).start();
      },
    },
  ],
}).catch((err) => console.error(err));
