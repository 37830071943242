/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { MedicationForm } from './wasting.model';

export enum FormularyWitnessRequirement {
  'None' = 0,
  'In-Person' = 1,
  'Anytime' = 2,
}

export enum DEAScheduleKey {
  None,
  I,
  II,
  III,
  IV,
  V,
}

export interface Formulary {
  id: number;
  itemCode: string;
  supplyVendorNum: string | null;
  supplyTradeName: string;
  supplyGenericName: string;
  supplyForm: string;
  supplyFormEnum: MedicationForm;
  manufacturerPartNum: string;
  supplySize: number;
  supplySizeUnits: string;
  supplyStrength: number | null;
  supplyStrengthUnit: string | null;
  supplyCost: number | null;
  supplyNDCCode: string | null;
  deaScheduleKey: DEAScheduleKey;
  formularyWitnessRequirement: FormularyWitnessRequirement;
  isHazardous: boolean;
  collectionProbability: number;
}
