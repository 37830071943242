/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { Injectable } from '@angular/core';
import { LocationPermissions } from '@midas/shared/common';
import { Store } from '@ngrx/store';
import { filter, map, Observable } from 'rxjs';
import { UserPermissionsActions, UserPermissionsSelectors } from './stores/user-location-permissions';

@Injectable({ providedIn: 'root' })
export class LocationPermissionsService {
  constructor(private store: Store) {}

  public checkTenantLocationPermissions(
    locationId: number | null,
    neededPermissions: LocationPermissions
  ): Observable<boolean> {
    return this.store.select(UserPermissionsSelectors.getUserPermissions).pipe(
      filter(({ loaded }) => {
        if (!loaded) {
          this.store.dispatch(UserPermissionsActions.getUserLocationPermissions());
        }
        return loaded;
      }),
      map(({ userLocationPermissions: userPermissions }): boolean => {
        const perm = userPermissions.find((l) => l.locationId === locationId);
        if (!perm || !perm.permissions) {
          return false;
        }

        if ((perm.permissions & neededPermissions) === neededPermissions) {
          return true;
        }

        return false;
      })
    );
  }

  public tenantHasPermissionsAtAnyLocation(neededPermissions: LocationPermissions): Observable<boolean> {
    return this.store.select(UserPermissionsSelectors.getUserPermissions).pipe(
      filter(({ loaded, loading }) => {
        if (!loaded && !loading) {
          this.store.dispatch(UserPermissionsActions.getUserLocationPermissions());
        }
        return loaded;
      }),
      map(({ userLocationPermissions: userPermissions }): boolean => {
        return userPermissions.some((p) => p.permissions & neededPermissions);
      })
    );
  }
}
