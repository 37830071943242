export const COverviewActionStyleAndIcons = {
  error: {
    statusStyle: 'color: var(--content-negative, #C62828)',
    cardStyle:
      'border-top: 20px solid var(--content-negative, #C62828); border: 3px solid var(--content-negative, #C62828)',
    canStatus: 'Error',
    status: 'Error',
    dialogStyle: 'background:var(--content-negative, #C62828);',
  },
  full: {
    statusStyle: 'color: var(--content-negative, #F57F17)',
    cardStyle:
      'border-top: 20px solid var(--content-negative, #F57F17); border: 3px solid var(--content-negative, #F57F17)',
    canStatus: 'Full',
    status: 'Full Capacity',
    dialogStyle: 'background:var(--content-negative, #F57F17);',
  },
  lockboxfull: {
    statusStyle: 'color: var(--content-warning, #304FFE)',
    cardStyle:
      'border-top: 20px solid var(--content-warning, #304FFE); border: 3px solid var(--content-warning, #304FFE)',
    canStatus: 'Lockbox Full',
    status: 'RX Intercep Samples',
    dialogStyle: 'background: var(--content-warning, #304FFE);',
  },
  readyForReview: {
    statusStyle: 'color: var(--content-accent, #8E24AA)',
    cardStyle:
      'border-top: 20px solid var(--content-accent, #8E24AA); border: 3px solid var(--content-accent, #8E24AA);',
    canStatus: 'Ready for Review',
    status: 'AnytimeWitness Ready',
    dialogStyle: 'background: var(--content-accent, #8E24AA);',
  },
};

export const COverviewActionStatusBar = [
  { icon: 'full', label: 'Error:', description: 'Require Immediate Attention', style: 'color: #C62828' },
  { icon: 'action-full', label: 'Full:', description: 'Waste Full', style: 'color: #F57F17' },
  { icon: 'lockbox-full', label: 'Lockbox Full:', description: 'RX Intercep Samples', style: 'color: #304FFE' },
  { icon: 'ready-for-review', label: 'Ready for Review:', description: 'AnytimeWitness Ready', style: 'color: #8E24AA' },
];
