export const overview_actions = {
  carts: [
    {
      id: 8,
      name: 'Dev Shared Cart',
      locationId: 14,
      locationName: 'Shared Hospital',
      organizationName: 'IQ Testing',
      collectionBins: [],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      inUse: false,
      error: true,
    },
    {
      id: 629,
      name: 'Jim',
      locationId: 14,
      locationName: 'Shared Hospital',
      organizationName: 'IQ Testing',
      collectionBins: [],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      inUse: false,
      readyForReview: true
    },
    {
      id: 637,
      name: 'WindowsLTSC version good',
      locationId: 7,
      locationName: 'Chui Hospital',
      organizationName: 'IQ Testing',
      collectionBins: [],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      inUse: false,
    },
    {
      id: 701,
      name: 'VIEW_2 (April)',
      locationId: 6,
      locationName: 'April Hospital',
      organizationName: 'IQ Testing',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: null,
          capacity: 200,
          wastedCount: 0,
          percentFull: 100,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: null,
          capacity: 200,
          wastedCount: 4,
          percentFull: 2,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      inUse: false,
    },
    {
      id: 728,
      name: 'anthony-dev-cart',
      locationId: 5,
      locationName: 'Anthony Hospital',
      organizationName: 'IQ Testing',
      collectionBins: [],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      inUse: false,
    },
    {
      id: 738,
      name: 'mini VIEW (April)',
      locationId: 6,
      locationName: 'April Hospital',
      organizationName: 'IQ Testing',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: null,
          capacity: 200,
          wastedCount: 0,
          percentFull: 0,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: null,
          capacity: 200,
          wastedCount: 3,
          percentFull: 2,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 100,
      },
      cartMode: 'Wasting',
      inUse: false,
    },
    {
      id: 759,
      name: 'OWO',
      locationId: 3,
      locationName: 'IQ Testing',
      organizationName: 'IQ Testing',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: null,
          capacity: 200,
          wastedCount: 0,
          percentFull: 0,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: null,
          capacity: 200,
          wastedCount: 0,
          percentFull: 0,
        },
      ],
      collectionBox: {
        wastedCount: 0,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 0,
      },
      cartMode: 'Wasting',
      inUse: false,
    },
    {
      id: 778,
      name: 'OFFICE5',
      locationId: 11,
      locationName: 'Roger Hospital',
      organizationName: 'IQ Testing',
      collectionBins: [
        {
          containerTypeName: 'Hazardous',
          serialNumber: null,
          capacity: 200,
          wastedCount: 0,
          percentFull: 0,
        },
        {
          containerTypeName: 'Non-Hazardous',
          serialNumber: null,
          capacity: 200,
          wastedCount: 0,
          percentFull: 100,
        },
      ],
      collectionBox: {
        wastedCount: 3,
        containerTypeName: 'Collection Box',
        capacity: 10,
        percentFull: 30,
      },
      cartMode: 'Wasting',
      inUse: false,
      error: true
    },
  ],
};
