export enum EOverviewViewIcons {
  full = 'full',
  empty = 'empty',
  almostFull = 'almostFull',
  inUse = 'inUse',
}

export enum EOverViewPercentFullLimits {
  almostFull = 78,
  full = 100,
}

export enum EOverviewLocationType {
  hospital= 'hospital',
  building= 'building',
  floor= 'floor',
  room= 'room',
}

export interface OverviewView {
  id: number;
  name: string;
  locationId: number;
  location: IOverciewLocation[];
  locationName: string;
  buildingName: string;
  buildingId: number;
  organizationName: string;
  collectionBins: ICollectionBins[];
  collectionBox: ICollectionBox;
  cartMode: number;
  inUse: boolean;
  locationTree: string;
  statusId: number;
}

export interface ICollectionBins {
  containerTypeName: string;
  serialNumber: number;
  capacity: number;
  wastedCount: number;
  percentFull: number;
}

export interface ICollectionBox {
  wastedCount: number;
  containerTypeName: string;
  capacity: number;
  percentFull: number;
}

export interface IOverviewStyleAndIconInfo {
  statusStyle: string;
  cardStyle: string;
  status: string;
  canStatus: string;
  dialogStyle: string;
}

export interface OverviewViewWithStyle extends OverviewView {
  styles: IOverviewStyleAndIconInfo;
  cardNumber: string;
}

export interface OverviewViewGroup {
  building: string;
  views: OverviewViewWithStyle[];
}

export interface IOverciewLocation {
  id: number;
  name: string;
  description: string;
  parentId: number;
  locationType: EOverviewLocationType;
}
