export interface IEChartFactory {
    type: EChartType; 
    data?: any; 
    datasetKeys?: string[];
    encode?: {x: string, y: string};
    sort?: EChartSorting;
    sortValue?: string;
    pieValuekey?: string;
    pieNameKey?: string;
  }
  
  
  export enum EChartSorting {
    desc = 'desc', 
    asc = 'asc'
  }
  
  export enum EChartType {
    gauge = 'gauge',
    bar = 'bar', 
    pie = 'pie',
    pieDoughnut = 'pieDoughnut'
  }