/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OverviewToolbarState, OVERVIEW_TOOLBAR_KEY } from './overview-toolbar.reducer';

const getOverviewToolbarState = createFeatureSelector<OverviewToolbarState>(OVERVIEW_TOOLBAR_KEY);

export const getOverviewToolbarSpace = createSelector(
  getOverviewToolbarState,
  (state: OverviewToolbarState) => state,
);
