/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { SortCriteriaItem } from './base.types';
import { Cart } from './cart.model';
import { Location } from './location.model';
import { ManagedUser } from './managed-user.model';
import { Wasting, WorkFlowStatus } from './wasting.model';
import { IWorkflow } from './workflow.model';

export interface Sample {
  id: number;
  wasting: Wasting;
  sampleIdentifier: string;
  isMissing: boolean;
}

export interface Transport extends IWorkflow {
  transportIdentifier: string | null;
  cart: Cart;
  location: Location;
  transportUser: ManagedUser;
  transportWitnessUser?: ManagedUser;
  samples: Sample[];
  transportCollectionTime: string;
}

export enum TransportsSortCriteria {
  Date,
  Location,
  Cart,
  User,
  Samples,
}

export type TransportsSortCriteriaItem = SortCriteriaItem<typeof TransportsSortCriteria>;

export interface QueryTransportsRequest {
  limit: number;
  offset: number;
  searchString?: string;
  locations?: number[];
  carts?: number[];
  users?: number[];
  startDate?: string | null;
  endDate?: string | null;
  sortCriteria?: TransportsSortCriteriaItem[];
  statuses: WorkFlowStatus[];
  showUserTransports?: boolean;
}

export enum TransportsPageType {
  AnytimeWitness,
  HistoricalWastes,
}

export function getUnapprovedWastes(transport: Transport) {
  return transport.samples.filter(
    (s) => s.wasting.wastingStatus !== WorkFlowStatus.Completed,
  ).length;
}

export function getMissingSamples(transport: Transport) {
  return transport.samples.filter(
    (s) => s.isMissing,
  ).length;
}
