export const numberWasteByHospital = 
{ 
    count: 10,
    items: [
        {
            id: 673,
            locationName:'Hospital 1',
            amountOfWaste: Math.floor(Math.random() * 100),
        },
        {
            id: 674,
            locationName:'Hospital 2',
            amountOfWaste: Math.floor(Math.random() * 100),
        },
        {
            id: 675,
            locationName:'Hospital 3',
            amountOfWaste: Math.floor(Math.random() * 100),
        },
        {
            id: 676,
            locationName:'Hospital 1',
            amountOfWaste: Math.floor(Math.random() * 100),
        },
        {
            id: 677,
            locationName:'Hospital 2',
            amountOfWaste: Math.floor(Math.random() * 100),
        },
        {
            id: 678,
            locationName:'Hospital 3',
            amountOfWaste: Math.floor(Math.random() * 100),
        },
        {
            id: 679,
            locationName:'Hospital 1',
            amountOfWaste: Math.floor(Math.random() * 100),
        },
        {
            id: 680,
            locationName:'Hospital 2',
            amountOfWaste: Math.floor(Math.random() * 100),
        },
        {
            id: 681,
            locationName:'Hospital 3',
            amountOfWaste: Math.floor(Math.random() * 100),
        },
        {
            id: 682,
            locationName:'Hospital 1',
            amountOfWaste: Math.floor(Math.random() * 100)
        },
    ]
}