/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { ApiError } from './lib/services';

export * from './lib/constants';
export * from './lib/init-loader/init-loader.service';
export * from './lib/models';
export * from './lib/provider';
export * from './lib/router';
export * from './lib/services';
export * from './lib/tokens';
export * from './lib/ui';
export * from './lib/constant';

export interface MidasEffectError {
  error: ApiError;
  showInDialog?: boolean;
}
