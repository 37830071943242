export const rxInterceptWaste = {
  count: 19,
  items: [
    {
      id: 1,
      locationName: 'Albany Medical Center',
      amountOfWaste: Math.floor(Math.random() * 100),
      date: '2024-01-20',
    },
    {
      id: 2,
      locationName: 'Colorado Northwell Hospital',
      amountOfWaste: Math.floor(Math.random() * 100),
      date: '2024-01-20',
    },
    {
      id: 3,
      locationName: "Miami Children's Hospital",
      amountOfWaste: Math.floor(Math.random() * 100),
      date: '2024-01-20',
    },
    {
      id: 4,
      locationName: 'New York Presbyterian Hospital',
      amountOfWaste: Math.floor(Math.random() * 100),
      date: '2024-01-20',
    },
    {
      id: 5,
      locationName: 'Northwell Health',
      amountOfWaste: Math.floor(Math.random() * 100),
      date: '2024-01-20',
    },
  ],
};
