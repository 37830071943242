export const numberWitnessByHospital = 
{ 
    count: 38,
    items: [
        {
            // id: 673,
            locationName:'ACMH Hospital',
            amountOfWaste: Math.floor(Math.random() * 100),
            // date: '2024-01-20'
        },
        // {
        //     id: 674,
        //     locationName:'Abbeville General Hospital',
        //     amountOfWaste: Math.floor(Math.random() * 100),
        //     date: '2024-02-20'
        // },
        // {
        //     id: 675,
        //     locationName:'Bayhealth Kent Campus',
        //     amountOfWaste: Math.floor(Math.random() * 100),
        //     date: '2024-03-20'
        // },
        // {
        //     id: 676,
        //     locationName:'Cambridge Medical Center',
        //     amountOfWaste: Math.floor(Math.random() * 100),
        //     date: '2024-01-20'
        // },
        // {
        //     id: 677,
        //     locationName:'ECU Health North Hospital',
        //     amountOfWaste: Math.floor(Math.random() * 100),
        //     date: '2024-02-20'
        // },
        // {
        //     id: 678,
        //     locationName:'Frederick Health Hospital',
        //     amountOfWaste: Math.floor(Math.random() * 100),
        //     date: '2024-03-20'
        // },
    ]
}