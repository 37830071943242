import { TypedAction } from "@ngrx/store/src/models";
import { Observable } from "rxjs";

interface IOverviewObjectBaseInterface {
    id: number,
    amountOfWaste: number
}

interface IOverviewAmountOfWasteBaseInterface {
    amountOfWaste: number;
}

export interface IOverviewStatisticsWasteByPerson extends IOverviewObjectBaseInterface{
    username: string,
}

export interface IOverviewStatisticsWasteByDrug extends IOverviewObjectBaseInterface {
    drug: string,
}

export interface IOverviewStatisticsRxInterceptWaste extends IOverviewObjectBaseInterface {
    center: string,
}

export interface IOverviewStadisticsStandardWasteLocation extends IOverviewObjectBaseInterface {
    locationName: string, 
}

export interface IOverviewStatisticsNumberOfWasteByHospital extends IOverviewAmountOfWasteBaseInterface {}

export interface IOverviewStatisticsNumberOfWaste extends IOverviewAmountOfWasteBaseInterface {}

export interface IOverviewStatisticsRxInterceptWasteByDateRange extends IOverviewAmountOfWasteBaseInterface {}

export interface IOverviewStatisticsNumberOfStandardWaste extends IOverviewAmountOfWasteBaseInterface{}

export interface OverviewStatisticsNumberOfWitness extends IOverviewAmountOfWasteBaseInterface{};

export interface IOverviewStatisticsNumberOfWitnessByHospital extends IOverviewAmountOfWasteBaseInterface{};


export interface IOverviewData{
    wasteByPersonData:  IOverviewStatisticsWasteByPerson[] | null;
    numberOfWasteData: number | null;
    wasteByDrugData:  IOverviewStatisticsWasteByDrug[] | null;
    numberOfWitnessData: number | null;
    wasteByHospitalData:  IOverviewStatisticsNumberOfWasteByHospital[] | null;
    numberOfWitnessByHospitalData: IOverviewStatisticsNumberOfWitnessByHospital[] | null;
    rxInterceptWasteData: IOverviewStatisticsRxInterceptWaste[] | null;
    numberOfStandardWasteData: number | null;
    standardWasteLocationData: IOverviewStadisticsStandardWasteLocation[] | null;
    rxInterceptWasteByDateRangeData: number | null;
}

export interface IOverviewLoaders{
    wasteByPersonLoader:  boolean;
    numberOfWasteLoader:  boolean;
    wasteByDrugLoader:  boolean;
    numberOfWitnessLoader:  boolean;
    wasteByHospitalLoader:  boolean;
    numberOfWitnessByHospitalLoader:  boolean;
    rxInterceptWasteLoader: boolean;
    numberOfStandardWasteLoader: boolean;
    standardWasteLocationLoader: boolean;
    rxInterceptWasteByDateRangeLoader: boolean;
}

export enum EWidget {
    numberOfWaste = 'numberOfWaste',
    wasteCountByPerson = 'wasteCountByPerson',
    wasteCountByDrug = 'wasteCountByDrug',
    numberOfWitness = 'numberOfWitness',
    wasteCountByHospital = 'wasteCountByHospital',
    numberOfWitnessByHospital = 'numberOfWitnessByHospital',
    rxInterceptWaste = 'rxInterceptWaste',
    numberOfStandardWaste = 'numberOfStandardWaste', 
    standardWasteLocation = 'standardWasteLocation',
    rxInterceptWasteByDateRange = 'rxInterceptWasteByDateRange'
}

export interface IWidgets {
    type: EWidget,
    title: string,
    data:  Observable<echarts.EChartsOption> | undefined,
    loaded: Observable<boolean> | null, 
    action: TypedAction<string>
}



