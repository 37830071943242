export const topTenWasteByPerson = 
{ 
    count: 10,
    items: [
        {
            id: 673,
            username:'Hannah Krause',
            amountOfWaste: Math.floor(Math.random() * 100),
            date: '2024-01-20'
        },
        {
            id: 674,
            username:'Zhao Qian',
            amountOfWaste: Math.floor(Math.random() * 100),
            date: '2024-01-20'
        },
        {
            id: 675,
            username:'Jasmin Krause ',
            amountOfWaste: Math.floor(Math.random() * 100),
            date: '2024-01-20'
        },
        {
            id: 676,
            username:'Li Lei',
            amountOfWaste: Math.floor(Math.random() * 100),
            date: '2024-01-20'
        },
        {
            id: 677,
            username:'Karle Neumann',
            amountOfWaste: Math.floor(Math.random() * 100),
            date: '2024-01-20'
        },
        {
            id: 678,
            username:'Adrian Groß',
            amountOfWaste: Math.floor(Math.random() * 100),
            date: '2024-01-20'
        },
        {
            id: 679,
            username:'Mia Neumann',
            amountOfWaste: Math.floor(Math.random() * 100),
            date: '2024-01-20'
        },
        {
            id: 680,
            username:'Böhm Fuchs',
            amountOfWaste: Math.floor(Math.random() * 100),
            date: '2024-01-20'
        },
        {
            id: 681,
            username:'Han Meimei',
            amountOfWaste: Math.floor(Math.random() * 100),
            date: '2024-01-20'
        },
        {
            id: 682,
            username:'Jhon Daoe',
            amountOfWaste: Math.floor(Math.random() * 100),
            date: '2024-01-20'
        },
    ]
}